import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { isValidElement, type ReactElement } from 'react'

type AdornmentVariants = 'start' | 'end'

const AdornmentVariantClasses: Record<AdornmentVariants, string> = {
  start: 'left-0',
  end: 'right-0',
}

type InputEndAdornmentProps = {
  variant: AdornmentVariants
  className?: string
  children: string | ReactElement
}

/**
 * @deprecated
 */
export function DeprecatedInputEndAdornment({
  variant,
  className,
  children,
}: InputEndAdornmentProps) {
  const variantClassName = AdornmentVariantClasses[variant]

  const isChildElement = isValidElement(children)

  return (
    <div
      className={clsx(
        className,
        variantClassName,
        'absolute inset-y-0 inline-flex items-center',
      )}
    >
      {isChildElement ? children : <span>{children}</span>}
    </div>
  )
}

export function ErrorInputAdornment() {
  return (
    <DeprecatedInputEndAdornment variant="end" className="px-2">
      <ExclamationTriangleIcon className="text-error-700 h-5 w-5" />
    </DeprecatedInputEndAdornment>
  )
}
