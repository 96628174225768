import * as AlertModalPrimative from '@radix-ui/react-alert-dialog'
import { forwardRef, ReactNode } from 'react'

import { cn } from '../utils'

const AlertModalRoot = AlertModalPrimative.Root

const AlertModalTrigger = AlertModalPrimative.Trigger

const AlertModalPortal = AlertModalPrimative.Portal

const AlertModalAction = AlertModalPrimative.Action
const AlertModalCancel = AlertModalPrimative.Cancel

const AlertModaloverlay = forwardRef<
  React.ElementRef<typeof AlertModalPrimative.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertModalPrimative.Overlay>
>(({ className, ...props }, ref) => (
  <AlertModalPrimative.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 grid place-items-center bg-gray-900 bg-opacity-40 p-4 sm:p-6',
      className,
    )}
    {...props}
  />
))
AlertModaloverlay.displayName = AlertModalPrimative.Overlay.displayName

type ModalSizeType = 'normal' | 'large' | 'fluid'

const AlertModalContent = forwardRef<
  React.ElementRef<typeof AlertModalPrimative.Content>,
  React.ComponentPropsWithoutRef<typeof AlertModalPrimative.Content> & {
    size?: ModalSizeType
  }
>(({ className, children, ...props }, ref) => (
  <AlertModalPortal>
    <AlertModaloverlay>
      <AlertModalPrimative.Content
        ref={ref}
        className={cn(
          {
            'max-w-full': props.size === 'fluid',
            'sm:max-w-[50%]': props.size === 'normal',
            'sm:w-full sm:!max-w-5xl': props.size === 'large',
            'sm:w-fit': props.size !== 'large',
          },
          'sm:min-w-51 relative grid max-h-full w-full max-w-lg overflow-y-auto rounded-lg bg-white shadow-lg',
          className,
        )}
        {...props}
      >
        {children}
      </AlertModalPrimative.Content>
    </AlertModaloverlay>
  </AlertModalPortal>
))
AlertModalContent.displayName = AlertModalPrimative.Content.displayName

const AlertModalHeader = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex items-center gap-4 border-b border-gray-200 p-6',
      className,
    )}
    {...props}
  >
    {children}
  </div>
)
AlertModalHeader.displayName = 'ModalHeader'

const AlertModalBody = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('p-6 text-gray-700', className)} {...props} />
)

const AlertModalFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse gap-1.5 rounded-b bg-gray-50 p-4 sm:flex-row sm:justify-end sm:p-6 md:flex-row md:items-center',
      className,
    )}
    {...props}
  />
)
AlertModalFooter.displayName = 'ModalFooter'

const AlertModalTitle = forwardRef<
  React.ElementRef<typeof AlertModalPrimative.Title>,
  React.ComponentPropsWithoutRef<typeof AlertModalPrimative.Title>
>(({ className, ...props }, ref) => (
  <AlertModalPrimative.Title
    ref={ref}
    className={cn('font-bold leading-none tracking-tight', className)}
    {...props}
  />
))
AlertModalTitle.displayName = AlertModalPrimative.Title.displayName

const AlertModalDescription = forwardRef<
  React.ElementRef<typeof AlertModalPrimative.Description>,
  React.ComponentPropsWithoutRef<typeof AlertModalPrimative.Description>
>(({ className, ...props }, ref) => (
  <AlertModalPrimative.Description
    ref={ref}
    className={cn('text-gray-700', className)}
    {...props}
  />
))
AlertModalDescription.displayName = AlertModalPrimative.Description.displayName

type ModalActionsProps = {
  children: ReactNode
  className?: string
}
function AlertModalActions({ children, className }: ModalActionsProps) {
  return (
    <div
      className={cn(
        'flex flex-col-reverse gap-2 sm:ml-auto sm:flex-row sm:justify-end',
        className,
      )}
    >
      {children}
    </div>
  )
}

export const AlertModal = Object.assign(AlertModalRoot, {
  Content: AlertModalContent,
  Description: AlertModalDescription,
  Footer: AlertModalFooter,
  Header: AlertModalHeader,
  Body: AlertModalBody,
  Overlay: AlertModaloverlay,
  Portal: AlertModalPortal,
  Title: AlertModalTitle,
  Trigger: AlertModalTrigger,
  Actions: AlertModalActions,
  Action: AlertModalAction,
  Cancel: AlertModalCancel,
})
