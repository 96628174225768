import { Tab as HeadlessTab } from '@headlessui/react'
import { createContext, Fragment, PropsWithChildren, useContext } from 'react'

import { cn } from '../utils'

type TabGroupContext = {
  /**
   * @default "default"
   * @description By default tabs are justified to the start of the container. The fitted variant grows
   * the tabs to fill the available space
   */
  variant?: 'default' | 'fitted'
}
const TabGroupContext = createContext<TabGroupContext | undefined>(undefined)
const useTabGroupContext = () => {
  const context = useContext(TabGroupContext)
  if (!context) {
    throw new Error('useTabGroupContext must be used within a Tab.Group')
  }
  return context
}

type GroupProps = PropsWithChildren<{
  /**
   * @description The default selected index
   */
  defaultIndex?: number
  /**
   * @description The selected index if you want to use the Tabs component as a controlled component.
   */
  selectedIndex?: number
  /**
   * @description A function called whenever the active tab changes.
   */
  onChange?: (index: number) => void
  /**
   * @description When true, the user can only display a panel via the keyboard by first navigating to it using the arrow keys, and then by pressing Enter or Space. By default, panels are automatically displayed when navigated to via the arrow keys. Note that this prop has no affect on mouse behavior.
   */
  manual?: boolean
}> &
  TabGroupContext

function Group({ variant = 'default', ...props }: GroupProps) {
  return (
    <TabGroupContext.Provider value={{ variant }}>
      <HeadlessTab.Group {...props} />
    </TabGroupContext.Provider>
  )
}

type ListProps = PropsWithChildren<{
  className?: string
}>

function List({ className, children, ...props }: ListProps) {
  const { variant } = useTabGroupContext()
  return (
    <HeadlessTab.List className="flex" {...props}>
      <div
        className={cn(
          'relative flex',
          'shadow-[inset_0_-2px_0_-1px] shadow-gray-300',
          {
            'gap-6': variant === 'default',
            'w-full': variant === 'fitted',
          },
          className,
        )}
      >
        {children}
      </div>
    </HeadlessTab.List>
  )
}

type TabProps = PropsWithChildren<{
  className?: string
  /**
   * @type {boolean}
   * @description Whether or not the Tab is currently disabled.
   */
  disabled?: boolean
}>

function TabRoot({ children, className, ...props }: TabProps) {
  const { variant } = useTabGroupContext()
  return (
    <HeadlessTab {...props} as={Fragment}>
      {({ selected }) => (
        <button
          className={cn(
            {
              'text-gray-500 hover:text-gray-900 hover:after:h-[2px] hover:after:bg-gray-400':
                !selected,
              'after:bg-primary-700 text-gray-900': selected,
              'flex-grow px-4': variant === 'fitted',
            },
            'relative after:absolute after:bottom-0 after:left-0 after:h-[3px] after:w-full',
            'py-3 shadow-[inset_0_0_0_1px] shadow-transparent',
            'focus-visible:shadow-primary-700 focus-visible:text-gray-900 focus-visible:outline-none',
            'active:shadow-primary-700 active:text-gray-900 active:outline-none',
            'disabled:cursor-not-allowed disabled:text-gray-400 disabled:after:bg-transparent disabled:hover:text-gray-400',
            className,
          )}
        >
          {children}
        </button>
      )}
    </HeadlessTab>
  )
}

type PanelsProps = PropsWithChildren<{
  className?: string
}>

function Panels(props: PanelsProps) {
  return <HeadlessTab.Panels {...props} />
}

type PanelProps = PropsWithChildren<{
  className?: string
}>

function Panel(props: PanelProps) {
  return <HeadlessTab.Panel {...props} />
}

export const Tab = Object.assign(TabRoot, {
  Group,
  List,
  Panels,
  Panel,
})
