import { DefaultBodyType, MockedRequest } from 'msw'

const handleUnhandledRequest = (request: MockedRequest<DefaultBodyType>) => {
  if (
    request.url.pathname.startsWith('/_next/') ||
    request.url.host === 'images.test.qogita.com'
  ) {
    return
  }
  console.error(
    'Found an unhandled %s request to %s',
    request.method,
    request.url.href,
  )
}

async function initMocks() {
  if (typeof window === 'undefined') {
    const { server } = await import('./server')
    server.listen({
      onUnhandledRequest: handleUnhandledRequest,
    })
  } else {
    const { worker } = await import('./browser')
    worker.start({
      onUnhandledRequest: handleUnhandledRequest,
    })
  }
}

// eslint-disable-next-line unicorn/prefer-top-level-await
initMocks()

export {}
