import clsx from 'clsx'
import { type DetailedHTMLProps, forwardRef } from 'react'

type InputRadioAttribute = DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

type RadioProps = Omit<InputRadioAttribute, 'id' | 'type'> & {
  id: string
  error?: boolean
}

/**
 * @deprecated
 */
export const DeprecatedRadio = forwardRef<HTMLInputElement, RadioProps>(
  function DeprecatedRadio({ children, error = false, ...props }, externalRef) {
    const hasError = props.disabled ? false : error

    return (
      <div className="flex items-center">
        <input
          {...props}
          ref={externalRef}
          type="radio"
          className={clsx(
            'focus:ring-primary-500 h-4 w-4 border-2 transition-all',
            {
              'text-primary-600 border-gray-300 hover:bg-gray-50': !hasError,
              'border-error-300 text-error-700 hover:bg-error-50': hasError,
            },
          )}
        />

        <label
          htmlFor={props.id}
          className="ml-3 block text-sm font-medium text-gray-700"
        >
          {children}
        </label>
      </div>
    )
  },
)
