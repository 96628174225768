import type { ComponentPropsWithoutRef } from 'react'

import { QogitaLogoEmblem } from '../QogitaLogo'

type PageErrorLogoProps = ComponentPropsWithoutRef<'svg'>

export function PageErrorLogo(props: PageErrorLogoProps) {
  return (
    <QogitaLogoEmblem
      {...props}
      theme="whiteOnPrimary"
      width={44}
      height={44}
    />
  )
}
