import { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { ComponentPropsWithoutRef } from 'react'
import { UrlObject } from 'url'

import { NextAnchor } from './NextAnchor'

export function useLoginLink() {
  const { asPath } = useRouter()

  const baseHref = {
    pathname: '/login/',
    query: {
      redirectTo: asPath,
    },
  }

  function getProps({
    href: customHref,
    ...props
  }: Omit<
    ComponentPropsWithoutRef<typeof NextAnchor>,
    'href' | 'color' | 'size' | 'appearance'
  > & { href?: (baseHref: UrlObject) => LinkProps['href'] } = {}): Omit<
    ComponentPropsWithoutRef<typeof NextAnchor>,
    'color' | 'size' | 'appearance'
  > {
    const href = customHref ? customHref(baseHref) : baseHref
    return {
      href,
      ...props,
    }
  }

  return { getProps }
}

/**
 * Link to the login page. Automatically adds a redirectTo query parameter based on user's current page
 */
export function LoginLink({
  children,
  color,
  appearance,
  size,
  ...props
}: Omit<ComponentPropsWithoutRef<typeof NextAnchor>, 'href'> & {
  href?: (baseHref: UrlObject) => LinkProps['href']
}) {
  const loginLink = useLoginLink()
  return (
    <NextAnchor
      color={color}
      appearance={appearance}
      size={size}
      {...loginLink.getProps(props)}
    >
      {children}
    </NextAnchor>
  )
}
