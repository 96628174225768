import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { cva, VariantProps } from 'class-variance-authority'
import {
  createContext,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useContext,
  useState,
} from 'react'
import { SetNonNullable, SetRequired } from 'type-fest'

import { developmentDisruptError } from '../../Utils'
import { cn } from '../utils'

const inlineNotificationVariants = cva(
  'flex items-start gap-4 overflow-hidden rounded p-3.5',
  {
    variants: {
      variant: {
        neutral: 'bg-gray-100 text-gray-900',
        info: 'border-info-700 bg-info-50 text-info-900 border',
        caution: 'border-caution-700 bg-caution-50 text-caution-900 border',
        success: 'border-success-700 bg-success-50 text-success-900 border',
        error: 'border-error-700 bg-error-50 text-error-900 border',
      },
    },
  },
)

type InlineNotifactionVariantProps = SetNonNullable<
  SetRequired<VariantProps<typeof inlineNotificationVariants>, 'variant'>,
  'variant'
>

type InlineNotificationContext = {
  onClose?: () => void
} & InlineNotifactionVariantProps

const InlineNotificationContext =
  createContext<InlineNotificationContext | null>(null)

function useInlineNotification() {
  const context = useContext(InlineNotificationContext)
  if (!context) {
    throw new Error(
      '[Qogita UI] InlineNotification components must be used within InlineNotification',
    )
  }
  return context
}

type InlineNotificationProps = InlineNotificationContext & {
  children: ReactNode
} & HTMLAttributes<HTMLDivElement>
const InlineNotificationRoot = forwardRef<
  HTMLDivElement,
  InlineNotificationProps
>(function InlineNotificationRoot({ children, className, ...props }, ref) {
  return (
    <div
      role="alert"
      {...props}
      className={cn(
        inlineNotificationVariants({ variant: props.variant }),
        className,
      )}
      ref={ref}
    >
      <InlineNotificationContext.Provider value={props}>
        {children}
      </InlineNotificationContext.Provider>
    </div>
  )
})

const iconsMap = {
  neutral: ExclamationCircleIcon,
  info: InformationCircleIcon,
  caution: ExclamationCircleIcon,
  success: CheckCircleIcon,
  error: ExclamationTriangleIcon,
} as const

const iconVariants = cva('h-6 w-6 flex-none', {
  variants: {
    variant: {
      neutral: 'mt-px text-gray-400',
      info: 'text-info-700 mt-px',
      caution: 'text-caution-700 mt-px',
      success: 'text-success-700 mt-px',
      error: 'text-error-700 mt-0.5',
    },
  },
})

function InlineNotificationIcon() {
  const { variant } = useInlineNotification()
  const Icon = iconsMap[variant]

  return <Icon className={iconVariants({ variant })} />
}

const inlineNotificationCloseButtonVariants = cva('h-5 w-5', {
  variants: {
    variant: {
      neutral: 'text-gray-400',
      info: 'text-info-700',
      caution: 'text-caution-700',
      success: 'text-success-700',
      error: 'text-error-700',
    },
  },
})

function InlineNotificationCloseButton() {
  const { onClose, variant } = useInlineNotification()

  if (!onClose) {
    developmentDisruptError(
      '[Qogita UI] InlineNotification.CloseButton used without passing onClose to InlineNotification. Pass the prop or remove the button.',
    )
  }

  return (
    <button
      onClick={onClose}
      className="flex-none p-0.5"
      aria-label="Close"
      type="button"
    >
      <XMarkIcon
        className={inlineNotificationCloseButtonVariants({ variant })}
      />
    </button>
  )
}

function InlineNotificationContent({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-1 flex-col items-start justify-between gap-1">
      {children}
    </div>
  )
}

type InlineNotificationTitleProps = {
  children: ReactNode
  className?: string
}
function InlineNotificationTitle({
  children,
  className,
}: InlineNotificationTitleProps) {
  return <p className={className}>{children}</p>
}

type InlineNotificationDescriptionProps = {
  children: ReactNode
  className?: string
}
function InlineNotificationDescription({
  children,
  className,
}: InlineNotificationDescriptionProps) {
  return <p className={className}>{children}</p>
}

export const InlineNotification = Object.assign(InlineNotificationRoot, {
  Icon: InlineNotificationIcon,
  CloseButton: InlineNotificationCloseButton,
  Content: InlineNotificationContent,
  Title: InlineNotificationTitle,
  Description: InlineNotificationDescription,
})

export function TestInlineNotification() {
  const [isOpen, setIsOpen] = useState(true)
  return isOpen ? (
    <InlineNotification variant="info" onClose={() => setIsOpen(false)}>
      <InlineNotification.Content>
        <InlineNotification.Title>Some title</InlineNotification.Title>
        <InlineNotification.Description>
          Some description
        </InlineNotification.Description>
      </InlineNotification.Content>
      <InlineNotification.CloseButton />
    </InlineNotification>
  ) : null
}
