import { cn } from '@qogita/ui'
import Image from 'next/image'
import Link from 'next/link'
import { createContext, useContext, useState } from 'react'

import { useTrackEvent } from '#lib/report/tracking'
import { vercelImageLoader } from '#lib/url'

import {
  navigationBrands,
  navigationCategories,
  NavigationCategory,
} from './constants.navigation'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from './NavigationMenu'

type DesktopNavigationContext = {
  activeMenu: string
  setActiveMenu: (menu: string) => void
}

const DesktopNavigationContext = createContext<
  DesktopNavigationContext | undefined
>(undefined)
function useDesktopNavigation() {
  const context = useContext(DesktopNavigationContext)
  if (context === undefined) {
    throw new Error(
      'DesktopNavigationContext compound components must be used within a DesktopNavigation',
    )
  }
  return context
}

const NavigationLink = ({
  href,
  children,
  className,
}: {
  href: string
  children: React.ReactNode
  className?: string
}) => {
  return (
    <Link legacyBehavior passHref href={href}>
      <NavigationMenuLink
        className={cn(
          'inline-block w-full rounded p-3 hover:bg-gray-100',
          className,
        )}
      >
        {children}
      </NavigationMenuLink>
    </Link>
  )
}

const DesktopNavigationMenuContent = ({
  children,
  name,
}: {
  children: React.ReactNode
  name: string
}) => {
  const { activeMenu } = useDesktopNavigation()
  return (
    <NavigationMenuContent
      // These onPointer events are here to disable our the open on hover behavior
      // as suggested in https://github.com/radix-ui/primitives/issues/1630
      onPointerEnter={(event) => event.preventDefault()}
      onPointerMove={(event) => event.preventDefault()}
      onPointerLeave={(event) => event.preventDefault()}
      className={cn('max-w-auto grid-cols-5 gap-4 bg-white p-6', {
        grid: activeMenu === name,
        hidden: activeMenu !== name,
      })}
      forceMount
    >
      {children}
    </NavigationMenuContent>
  )
}

const NavigationTrigger = ({ children }: { children: React.ReactNode }) => {
  return (
    <NavigationMenuTrigger
      // These onPointer events are here to disable our the open on hover behavior
      // as suggested in https://github.com/radix-ui/primitives/issues/1630
      onPointerEnter={(event) => event.preventDefault()}
      onPointerMove={(event) => event.preventDefault()}
      onPointerLeave={(event) => event.preventDefault()}
      className="group/trigger flex h-auto rounded-none p-0 px-4 text-base font-bold"
    >
      <div className="py-3 group-hover/trigger:shadow-[inset_0_-1px_0_rgba(0,0,0,1)] group-focus/trigger:shadow-[inset_0_-1px_0_rgba(0,0,0,1)] group-data-[state=open]/trigger:shadow-[inset_0_-1px_0_rgba(0,0,0,1)]">
        {children}
      </div>
    </NavigationMenuTrigger>
  )
}

const NavListItem = ({
  slug,
  children,
}: {
  slug: string
  children: React.ReactNode
}) => {
  return (
    <li>
      <NavigationLink
        className="text-sm"
        href={`/categories/health-beauty/${slug}`}
      >
        {children}
      </NavigationLink>
    </li>
  )
}

const Lvl2List = ({ children }: { children: React.ReactNode }) => {
  return <ul className="col-span-4 grid grid-cols-4 gap-4">{children}</ul>
}

const Lvl3List = ({ children }: { children: React.ReactNode }) => {
  return <ul className="flex flex-col">{children}</ul>
}

const CategoryMenuItem = ({
  navigationCategory,
}: {
  navigationCategory: NavigationCategory
}) => {
  return (
    <NavigationMenuItem value={navigationCategory.name}>
      <NavigationTrigger>{navigationCategory.name}</NavigationTrigger>
      <DesktopNavigationMenuContent name={navigationCategory.name}>
        <div className="border-r pr-4">
          {navigationCategory.slug ? (
            <NavigationLink
              className="text-base font-bold"
              href={`/categories/health-beauty/${navigationCategory.slug}`}
            >
              All {navigationCategory.name}
            </NavigationLink>
          ) : (
            <div className="p-3 text-base font-bold">
              {navigationCategory.name}
            </div>
          )}
        </div>
        <Lvl2List>
          {navigationCategory.subCategories.map((subCategory, index) => (
            <li key={`${index} - ${subCategory.slug}`}>
              <div className="p-3 text-sm font-bold">{subCategory.name}</div>
              <Lvl3List>
                {subCategory.subCategories.map((subSubCategory) => (
                  <NavListItem
                    key={subSubCategory.slug}
                    slug={subSubCategory.slug}
                  >
                    {subSubCategory.name}
                  </NavListItem>
                ))}
                {subCategory.slug ? (
                  <NavListItem slug={subCategory.slug}>
                    Shop all {subCategory.name}
                  </NavListItem>
                ) : null}
              </Lvl3List>
            </li>
          ))}
        </Lvl2List>
      </DesktopNavigationMenuContent>
    </NavigationMenuItem>
  )
}

export const DesktopNavigation = () => {
  const { trackNavigationMenuOpened } = useTrackEvent()
  const [activeMenu, setActiveMenu] = useState('')

  return (
    <DesktopNavigationContext.Provider value={{ activeMenu, setActiveMenu }}>
      <div className="flex w-full flex-row justify-center border-b border-gray-900">
        <NavigationMenu
          onValueChange={(value) => {
            if (value) {
              trackNavigationMenuOpened({ menuType: value })
            }
            setActiveMenu(value)
          }}
          className="max-w-site-content z-20"
        >
          <NavigationMenuList>
            <CategoryMenuItem
              navigationCategory={navigationCategories.fragrance}
            />
            <CategoryMenuItem
              navigationCategory={navigationCategories.makeup}
            />
            <CategoryMenuItem navigationCategory={navigationCategories.hair} />
            <CategoryMenuItem
              navigationCategory={navigationCategories.skincare}
            />
            <CategoryMenuItem
              navigationCategory={navigationCategories.bathShower}
            />
            <CategoryMenuItem
              navigationCategory={navigationCategories.nailCare}
            />
            <CategoryMenuItem
              navigationCategory={navigationCategories.shavingGrooming}
            />
            <NavigationMenuItem value="Brands">
              <NavigationTrigger>Brands</NavigationTrigger>
              <NavigationMenuContent
                className={cn('grid-cols-5 gap-4 bg-white p-6', {
                  grid: activeMenu === 'Brands',
                  hidden: activeMenu !== 'Brands',
                })}
                forceMount
              >
                <div className="border-r pr-4">
                  <NavigationLink
                    className="text-base font-bold"
                    href={`/brands`}
                  >
                    Premium Brands A-Z
                  </NavigationLink>
                </div>
                <ul className="col-span-4 grid grid-cols-5 gap-4">
                  {navigationBrands.map((brand) => (
                    <li key={brand.name}>
                      <Link
                        legacyBehavior
                        passHref
                        href={{
                          pathname: '/brands/[brand]',
                          query: { brand: brand.slug },
                        }}
                      >
                        <NavigationMenuLink>
                          <Image
                            className="mb-2 rounded p-2 py-6 shadow"
                            loader={vercelImageLoader}
                            src={brand.logo}
                            alt={brand.name}
                          />
                          <div className="text-sm font-medium">
                            {brand.name}
                          </div>
                        </NavigationMenuLink>
                      </Link>
                    </li>
                  ))}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </DesktopNavigationContext.Provider>
  )
}
