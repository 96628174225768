import { logError } from '@qogita/logging'
import ky from 'ky'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { z } from 'zod'

import { getCookie, setBrowserCookie } from '#lib/cookie'
import { ONE_YEAR_IN_SECONDS } from '#lib/time'

const tapfiliateQueryParamsSchema = z.object({
  ref: z.string().optional(),
})

/**
 * Grabs the `ref` query parameter from the URL, if it exists.
 * Otherwise returns null
 */
function useTapfiliateReferralCode() {
  const { query } = useRouter()
  const parsedQueryResult = tapfiliateQueryParamsSchema.safeParse(query)
  if (!parsedQueryResult.success) {
    return null
  }
  return parsedQueryResult.data.ref ?? null
}

/**
 * Sends a click event to Tapfiliate when the user lands on the site with a referral code
 * Stores the click id in a cookie for later use
 *
 * This is a component rather than a hook so it can be conditionally nested inside cookie consent components
 */
export function Tapfiliate() {
  const tapfiliateReferralCode = useTapfiliateReferralCode()

  useEffect(() => {
    if (!tapfiliateReferralCode) {
      return
    }

    const existingClickId = getCookie(
      document.cookie,
      TAPFILIATE_CLICK_ID_COOKIE_NAME,
    )

    if (existingClickId) {
      // If we already have a tapfiliate click id, then we don't need to send another click event
      return
    }

    ky.post('/api/tapfiliate-proxy/clicks', {
      json: {
        referral_code: tapfiliateReferralCode,
      },
    })
      .json<{ id: string }>()
      .then(({ id }) => {
        setBrowserCookie(TAPFILIATE_CLICK_ID_COOKIE_NAME, id, {
          maxAge: ONE_YEAR_IN_SECONDS,
        })
      })
      .catch((error) => {
        logError(error)
      })
  }, [tapfiliateReferralCode])

  return null
}

const TAPFILIATE_CLICK_ID_COOKIE_NAME = 'tapfiliate_id'

function getTapfiliateClickId() {
  return getCookie(document.cookie, TAPFILIATE_CLICK_ID_COOKIE_NAME)
}

export function tapfiliateSignupUser({ email }: { email: string }) {
  const tapfiliateClickId = getTapfiliateClickId()
  if (!tapfiliateClickId) {
    // If we don't have a tapfiliate click id, then this isn't an affiliate signup so do nothing
    return
  }
  return ky
    .post('/api/tapfiliate-proxy/customers', {
      json: {
        customer_id: email,
        click_id: tapfiliateClickId,
        status: 'new',
      },
    })
    .catch((error) => {
      logError(error)
    })
}

export function tapfiliateConversion({
  orderQid,
  amount,
  email,
  currency,
}: {
  orderQid: string
  amount: number
  email?: string
  currency?: string
}) {
  const tapfiliateClickId = getTapfiliateClickId()
  if (!tapfiliateClickId) {
    // If we don't have a tapfiliate click id, then this isn't an affiliate conversion so do nothing
    return
  }

  ky.post('/api/tapfiliate-proxy/conversions/', {
    json: {
      click_id: tapfiliateClickId,
      external_id: orderQid,
      customer_id: email,
      amount,
      currency,
    },
  }).catch((error) => {
    logError(error)
  })
}
