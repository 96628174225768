import { Toast, ToastProvider, ToastViewport } from './Toast'
import { useToast } from './useToast'

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, toastType, toast, ...props }) {
        // Here we can add more toast types if we want, with standard formatting
        // 'custom' allows you to pass in a custom component that looks however you like
        switch (toastType) {
          case 'custom':
            return (
              <Toast key={id} {...props}>
                {toast}
              </Toast>
            )
        }
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
