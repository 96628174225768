import { useFlags } from 'launchdarkly-react-client-sdk'

// This is an example usage of the useFlags hook
// Keeping it here so we don't end up with a disallowed empty file when we don't currently have
// any flags in use
// ts-unused-exports:disable-next-line
export const useExampleFlag = () => {
  const flags = useFlags()

  return flags.exampleFlag === true
}

export const useOptimizerIncreaseQuantityForUnitConstraints = () => {
  const flags = useFlags()

  return flags.optimizerIncreaseQuantityForUnitConstraints === true
}

export const useShowMonduMarketing = () => {
  const flags = useFlags()

  return flags.showMonduMarketing === true
}

export const useIsBuyersRevitalizedClaimsPageEnabled = () => {
  const flags = useFlags()

  return flags.buyersRevitalizedClaimsPage === true
}

export const useMarketplaceInvoiceRequestLink = () => {
  const flags = useFlags()

  return flags.marketplaceInvoiceRequestLink === true
}
