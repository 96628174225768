type FetchErrorContext = {
  statusCode: number
  responseBody: unknown
  url: string
}

export class FetchError extends Error {
  dd_fingerprint?: string

  constructor(
    message: string,
    public context: FetchErrorContext,
  ) {
    // We add a prefix to the message so we can identify it in datadog
    // React will always log thrown errors to the console, so we'll see them in the logs
    // even if we handle them with an error boundary
    const messagePrefix = 'QogitaFetchError'
    super(`${messagePrefix}: ${message}`)
    this.name = 'FetchError'

    // Add a fingerprint to group 401 errors together into a single issue in datadog
    if (context.statusCode === 401) {
      this.dd_fingerprint = 'qogita_fetch_error_401'
    }
  }
}

export class DisallowedUserCurrencyError extends Error {
  constructor(
    message: string,
    public context: { currency: string },
  ) {
    super(message)
    this.name = 'DisallowedUserCurrencyError'
  }
}
