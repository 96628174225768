import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { createContext, Fragment, ReactNode, useContext, useState } from 'react'

type DrawerContextType = {
  align: 'left' | 'right'
  onClose: () => void
}

const DrawerContext = createContext<DrawerContextType | null>(null)
const useDrawerContext = () => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error(
      '[Qogita UI] Drawer components must be used inside a <Drawer> component',
    )
  }
  return context
}

type ContentProps = {
  children: ReactNode
  className?: string
}

const Content = ({ children, className }: ContentProps) => {
  const { align } = useDrawerContext()
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom={align === 'right' ? 'translate-x-full' : '-translate-x-full'}
      enterTo="translate-x-0"
      leave="ease-in duration-200"
      leaveFrom="translate-x-0"
      leaveTo={align === 'right' ? 'translate-x-full' : '-translate-x-full'}
    >
      <Dialog.Panel
        className={clsx(
          'fixed top-0 flex h-screen w-full max-w-[min(20rem,calc(100%-3.75rem))] overflow-auto bg-white', //3.75rem - close button reserved space; 20rem - fixed max-width for screens with width > 23.75rem: 380px  + 60px (button's reserved space)
          {
            'right-0 flex-row-reverse': align === 'right',
            'left-0': align === 'left',
          },
          className,
        )}
      >
        {children}
      </Dialog.Panel>
    </Transition.Child>
  )
}

type CloseButtonProps = {
  className?: string
  children: ReactNode
}
const CloseButton = ({ className, children }: CloseButtonProps) => {
  const { onClose } = useDrawerContext()
  return (
    <button onClick={onClose} title="Close" className={className}>
      {children}
    </button>
  )
}

const Backdrop = () => {
  const { align } = useDrawerContext()
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="fixed inset-0 bg-gray-900 bg-opacity-50"
        aria-hidden="true"
      >
        <CloseButton
          className={clsx('absolute top-6', {
            //2.75rem - represents the space between the button and the side of the screen + half of button size
            //21.5rem - represents the Drawer's max-width (320px/20rem) + 1.5rem (24px) padding
            //these are necessary to keep the Close button right next to the Drawer
            'left-[min(calc(100%-2.75rem),21.5rem)]': align === 'left',
            'right-[min(calc(100%-2.75rem),21.5rem)]': align === 'right',
          })}
        >
          <XMarkIcon className="w-6 text-white" />
        </CloseButton>
      </div>
    </Transition.Child>
  )
}

type DrawerRootProps = {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  align?: 'left' | 'right'
  className?: string
}
export const DrawerRoot = ({
  isOpen,
  onClose,
  children,
  className,
  align = 'left',
}: DrawerRootProps) => {
  return (
    <DrawerContext.Provider value={{ align, onClose }}>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          open={isOpen}
          onClose={onClose}
          className={clsx('relative z-10', className)}
        >
          {children}
        </Dialog>
      </Transition.Root>
    </DrawerContext.Provider>
  )
}

export const DrawerTestComponent = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <button onClick={() => setOpen(true)}>Open drawer</button>
      <Drawer
        isOpen={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <Drawer.Backdrop />
        <Drawer.Content>
          <ul>
            <li>Item 1</li>
            <li>Item 2</li>
            <li>Item 3</li>
          </ul>
        </Drawer.Content>
      </Drawer>
    </>
  )
}

export const Drawer = Object.assign(DrawerRoot, {
  Content,
  Backdrop,
  CloseButton,
})
