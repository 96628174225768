import type { LegacyRef, MutableRefObject, RefCallback } from 'react'

export function mergeReferences<T>(
  references: Array<MutableRefObject<T> | LegacyRef<T>>,
): RefCallback<T> {
  return (value) => {
    references.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ;(ref as MutableRefObject<T | null>).current = value
      }
    })
  }
}
