export const ONE_HOUR_MS = 3_600_000
export const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 365

type GetFormattedDate = (
  value: number | string | Date,
  option: Intl.DateTimeFormatOptions,
  locale?: string,
) => string

/**
 * Format option for full month name, numeric day and year,
 * e.g. April 8, 2022.
 */
export const DATE_FORMAT_OPTION = {
  dateStyle: 'long',
} as const
/**
 * Format option for full month name, numeric day, year, hour and minute
 * in 24 hour cycle. e.g. April 8, 2020, 12:29.
 */
export const DATE_TIME_FORMAT_OPTION = {
  dateStyle: 'long',
  timeStyle: 'short',
  hourCycle: 'h24',
} as const

/**
 * Format a unix timestamp/date-time string/Date object to a date time string.
 * @param value A unix timestamp/date-time string/Date object value.
 * @param option A format option.
 * @param locale An optional locale. Default: 'en-US'.
 * @returns A date time string.
 */
export const getFormattedDate: GetFormattedDate = (value, option, locale) => {
  const date = new Date(value)
  const formattedDate = new Intl.DateTimeFormat(locale, option).format(date)
  return formattedDate
}
