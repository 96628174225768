import clsx from 'clsx'
import { HTMLAttributes } from 'react'

type AdornmentProps = {
  isActive: boolean
} & HTMLAttributes<HTMLDivElement>

export function NavigationAdornment({
  isActive,
  className,
  ...rest
}: AdornmentProps) {
  return (
    <div
      className={clsx(
        'flex grow before:absolute before:bottom-1 before:top-1 before:w-1 before:rounded-r',
        { 'before:bg-primary-700': isActive },
        className,
      )}
      {...rest}
    />
  )
}
