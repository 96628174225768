import { SvgAttr as SvgAttribute } from '../../utils/icon.interface'

export function MasterCard(props: SvgAttribute) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      {...props}
      fill="none"
    >
      <title>MasterCard</title>
      <path
        d="M18.189 15.997h-.413v.231a.72.72 0 0 0-.6-.278c-.532 0-.95.418-.95.997 0 .58.418.997.95.997a.72.72 0 0 0 .6-.278v.23h.413v-1.899Zm-.961 1.557c-.358 0-.577-.274-.577-.608 0-.334.22-.608.577-.608s.571.262.571.608c0 .345-.23.608-.571.608Zm-1.908-1.216c.18 0 .342.06.498.171l.198-.334c-.206-.162-.397-.226-.683-.226-.611 0-1.012.42-1.012.997 0 .576.4.997 1.012.997.286 0 .477-.064.683-.227l-.198-.333a.834.834 0 0 1-.498.17c-.33-.003-.572-.242-.572-.607 0-.366.243-.604.572-.608Zm-1.417-.388a.56.56 0 0 0-.5.278v-.23h-.409v1.897h.413v-1.064c0-.314.135-.488.405-.488a.669.669 0 0 1 .258.047l.127-.389a.878.878 0 0 0-.294-.052Zm-2.235-.001c-.557 0-.946.405-.946.997s.405.997.973.997c.286 0 .548-.071.779-.266l-.203-.306a.905.905 0 0 1-.552.199c-.266 0-.508-.124-.568-.465h1.41a2.04 2.04 0 0 0 .008-.16c-.004-.591-.37-.996-.902-.996Zm-.513.83c.044-.273.21-.46.504-.46.266 0 .437.167.48.46h-.984Zm8.487-.83a.56.56 0 0 0-.5.278v-.23h-.41v1.898h.414v-1.064c0-.314.135-.488.405-.488a.667.667 0 0 1 .258.047l.127-.389a.88.88 0 0 0-.294-.052Zm3.135 1.864a.047.047 0 0 0 .014-.036.05.05 0 0 0-.018-.04.08.08 0 0 0-.052-.016h-.08v.175h.037v-.065h.018l.053.066h.044l-.058-.066a.07.07 0 0 0 .042-.018Zm-.1-.013v-.046h.044c.008 0 .016.001.023.005a.02.02 0 0 1 .009.018.02.02 0 0 1-.009.017.046.046 0 0 1-.023.006h-.043Zm-1.068-1.571a.72.72 0 0 0-.6-.279c-.532 0-.95.418-.95.997 0 .58.418.997.95.997a.72.72 0 0 0 .6-.278v.23h.413v-2.661h-.413v.994Zm-.548 1.326c-.358 0-.576-.274-.576-.608 0-.334.218-.608.576-.608.357 0 .571.262.571.608 0 .345-.23.608-.571.608Z"
        fill="#231F20"
      />
      <path
        d="M22.887 17.738a.194.194 0 0 0-.177-.116v-.001a.193.193 0 0 0-.178.115c-.02.047-.02.1 0 .146.01.023.023.045.04.063a.198.198 0 0 0 .213.04.193.193 0 0 0 .102-.1c.02-.047.02-.1 0-.147Zm-.043.13a.149.149 0 0 1-.077.077.137.137 0 0 1-.057.012v.001a.148.148 0 0 1-.136-.09.146.146 0 0 1 .136-.204.146.146 0 0 1 .103.043.15.15 0 0 1 .031.16ZM9.98 17.55c-.207 0-.275-.127-.275-.317v-.858h.675v-.378h-.675v-.576h-.417v.576h-.385v.377h.385v.866c0 .44.171.703.66.703a.97.97 0 0 0 .516-.147l-.12-.353a.764.764 0 0 1-.365.107Zm-3.857-1.553v.231a.72.72 0 0 0-.6-.278c-.532 0-.95.418-.95.997 0 .58.418.997.95.997a.72.72 0 0 0 .6-.278v.23h.412v-1.899h-.412Zm-.548 1.558c-.358 0-.576-.275-.576-.608 0-.334.218-.608.576-.608.357 0 .572.262.572.608 0 .345-.23.608-.572.608ZM3.462 15.95c-.243-.004-.493.071-.668.338a.697.697 0 0 0-.627-.338.628.628 0 0 0-.556.282v-.235h-.413v1.898h.416v-1.052c0-.33.183-.505.465-.505.282 0 .413.179.413.5v1.057h.417v-1.052c0-.33.19-.505.465-.505.274 0 .416.179.416.5v1.057h.419v-1.19c0-.45-.286-.751-.747-.755Zm4.5.806-.195-.028c-.178-.023-.321-.059-.321-.186 0-.139.134-.223.361-.223.242 0 .477.092.592.163l.179-.334h-.001c-.199-.13-.472-.198-.774-.198-.481 0-.79.23-.79.607 0 .31.23.5.654.56l.195.028c.227.032.333.091.333.199 0 .146-.15.23-.432.23a1.02 1.02 0 0 1-.632-.198l-.194.322c.227.166.513.246.822.246.548 0 .866-.258.866-.62 0-.334-.25-.508-.663-.568Z"
        fill="#231F20"
      />
      <path
        d="m22.841 11.303-.084.191-.083-.19h-.072v.277h.051v-.21l.078.181h.054l.078-.18v.21h.05v-.279h-.072Zm-.294.001h-.236v.047h.092v.23h.051v-.23h.093v-.047Z"
        fill="#F79410"
      />
      <path
        d="M8.837 7.15a7.138 7.138 0 0 0 2.73 5.622h.002a7.137 7.137 0 0 0 2.73-5.622 7.137 7.137 0 0 0-2.73-5.621h-.001a7.139 7.139 0 0 0-2.73 5.621Z"
        fill="#FF5F00"
      />
      <path
        d="M7.15 14.3a7.12 7.12 0 0 0 4.418-1.528 7.138 7.138 0 0 1-2.73-5.622 7.138 7.138 0 0 1 2.73-5.621A7.15 7.15 0 0 0 0 7.15a7.15 7.15 0 0 0 7.15 7.15Z"
        fill="#EB001B"
      />
      <path
        d="M14.3 7.15a7.137 7.137 0 0 1-2.732 5.622A7.15 7.15 0 0 0 23.137 7.15a7.15 7.15 0 0 0-11.569-5.62A7.137 7.137 0 0 1 14.3 7.15Z"
        fill="#F79E1B"
      />
    </svg>
  )
}
