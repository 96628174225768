import Image from 'next/image'

import { TestimonialCardStoryblok } from '#types/storyblok-component-types'

import { CmsPanelErrorBoundary } from './CmsPanelErrorBoundary'
import { storyblokImageLoader } from './storyblokImageLoader'

function TestimonialCardComponent({
  blok,
}: {
  blok: TestimonialCardStoryblok
}) {
  return (
    <div className="border-primary-700 border-l-2 pl-8">
      <div className="flex flex-col gap-6">
        <q>{blok.quote}</q>
        <figure className="flex flex-row items-center gap-4">
          {blok.personAvatar ? (
            <div className="relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full">
              <Image
                loader={storyblokImageLoader}
                src={blok.personAvatar.filename}
                alt={blok.personAvatar.alt || ''}
                height={40}
                width={40}
              />
            </div>
          ) : null}
          <figcaption>
            <div className="text-base font-bold">{blok.personName}</div>
            {blok.personDescription ? (
              <div className="text-sm">{blok.personDescription}</div>
            ) : null}
          </figcaption>
        </figure>
      </div>
    </div>
  )
}

export function TestimonialCard({ blok }: { blok: TestimonialCardStoryblok }) {
  return (
    <CmsPanelErrorBoundary>
      <TestimonialCardComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
