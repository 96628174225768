import { logError } from '@qogita/logging'
import { Anchor, QogitaLogoWordmark } from '@qogita/ui'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import clsx from 'clsx'
import Link from 'next/link'
import Script from 'next/script'
import { ReactNode, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import {
  AmexIcon,
  ApplePayIcon,
  GooglePayIcon,
  IdealIcon,
  InstagramIcon,
  LinkedinIcon,
  MasterCardIcon,
  TwitterIcon,
  UnionPayIcon,
  VisaIcon,
  WhatsAppIcon,
} from '#components/Icons'
import { LinkExternal } from '#components/LinkExternal'
import { useAuthentication } from '#contexts/Authentication'
import {
  ConsentModal,
  ConsentModalTrigger,
  CookieSettingsModelContent,
  useConsent,
} from '#contexts/Consent'
import { useShowMonduMarketing } from '#hooks/featureFlags'
import { PageStoryblok } from '#types/storyblok-component-types'

import { MonduLogo } from './MonduLogo'
import { RegisterLink } from './RegisterLink'

const date = new Date()

const HubspotSubscribe = () => {
  return (
    <>
      <Script id="hubspot-subscribe-form">
        {`
hbspt.forms.create({
  region: "eu1",
  portalId: "139645065",
  formId: "40e163ee-e789-4477-9b17-070527f3b63a",
  target: '#hubspot-subscribe-form',
  cssClass: 'hubspot-subscribe-form',
});
        `}
      </Script>
      <div id="hubspot-subscribe-form" />
    </>
  )
}

type FooterLinkListProps = {
  children: ReactNode
}
const FooterLinkList = ({ children }: FooterLinkListProps) => {
  return <ul className="flex flex-col gap-2">{children}</ul>
}

type FooterLinkListHeaderProps = {
  children: ReactNode
}
const FooterLinkListHeader = ({ children }: FooterLinkListHeaderProps) => {
  return <h2 className="mb-2 text-base font-bold">{children}</h2>
}

type FooterRowProps = {
  children: ReactNode | undefined
  className?: string
}
const FooterRow = ({ children, className }: FooterRowProps) => {
  return (
    <div>
      <div
        className={clsx(
          'max-w-site-content mx-auto px-4 py-12 md:px-6',
          className,
        )}
      >
        {children}
      </div>
    </div>
  )
}

type FooterLinkProps = {
  href: string
  children: ReactNode
  className?: string
  target?: string
  sbBlokData?: SbBlokData
}
const FooterLink = ({
  href,
  children,
  className,
  target,
  sbBlokData,
}: FooterLinkProps) => {
  return (
    <Link
      {...sbBlokData}
      href={href}
      target={target}
      className={clsx(
        'outline-primary-700 text-gray-700 outline-offset-4',
        className,
      )}
    >
      {children}
    </Link>
  )
}

const CmsFooter = ({ pageBlok }: { pageBlok: PageStoryblok }) => {
  const footer = pageBlok.footer[0]

  if (!footer) {
    throw new TypeError(
      'Invalid footer: expected an array of storyblok components with at least one element',
    )
  }

  if (typeof footer === 'string') {
    // will never happen because we resolve relations in the api request
    throw new TypeError(
      'Invalid footer: did you resolve the storyblok relations?',
    )
  }

  return (
    <FooterRow
      className="grid grid-cols-2 gap-x-4 gap-y-14 py-12 sm:grid-cols-2 md:flex md:justify-between md:gap-x-20"
      {...storyblokEditable(footer.content)}
    >
      {footer?.content.sections?.map((blok) => {
        return (
          <div key={blok._uid}>
            <FooterLinkListHeader>{blok.title}</FooterLinkListHeader>
            <FooterLinkList>
              {blok.links?.map((blok) => (
                <li key={blok._uid}>
                  <FooterLink
                    sbBlokData={storyblokEditable(blok)}
                    target={blok.link.target}
                    // If the link is an `internal` link in Storyblok it'll have a nested story, otherwise if external we can use the bare `url`
                    // https://www.storyblok.com/docs/guide/in-depth/rendering-the-link-field
                    href={blok.link.story?.url ?? blok.link.url}
                  >
                    {blok.label}
                  </FooterLink>
                </li>
              ))}
            </FooterLinkList>
          </div>
        )
      })}
    </FooterRow>
  )
}

const Footer = ({
  pageBlok,
}: {
  pageBlok: PageStoryblok | null
}): JSX.Element => {
  const footerRef = useRef<HTMLElement | null>(null)
  const { consent } = useConsent()
  const { isAuthenticated } = useAuthentication()
  const showMonduMarketing = useShowMonduMarketing()

  return (
    <footer className="relative border-t border-t-gray-900" ref={footerRef}>
      <div className="divide-y divide-gray-900">
        <FooterRow className="flex flex-col items-stretch justify-between sm:flex-row sm:gap-x-6">
          <div className="mb-12 flex flex-col sm:mb-0 sm:justify-between">
            <div className="mb-10 flex">
              <Link href="/">
                <QogitaLogoWordmark
                  className="h-[2.5rem] w-[9rem]"
                  theme="black"
                />
              </Link>
              <ul className="ml-8 flex list-none flex-row items-center gap-2.5 sm:ml-10 sm:gap-6">
                <li>
                  <LinkExternal href="https://twitter.com/qogita">
                    <TwitterIcon
                      title="Twitter"
                      className="h-4.5 w-4.5 fill-gray-900"
                    />
                  </LinkExternal>
                </li>
                <li>
                  <LinkExternal href="https://www.linkedin.com/company/qogita/">
                    <LinkedinIcon
                      title="Linkedin"
                      className="h-4.5 w-4.5 fill-gray-900"
                    />
                  </LinkExternal>
                </li>
                <li>
                  <LinkExternal href="https://www.instagram.com/qogita_wholesale/">
                    <InstagramIcon
                      title="Instagram"
                      className="h-4.5 w-4.5 fill-gray-900"
                    />
                  </LinkExternal>
                </li>
              </ul>
            </div>
            {isAuthenticated ? (
              <div>
                <p className="mb-1 text-base font-bold">
                  Message us on WhatsApp
                </p>
                <p className="mb-1">Weekdays from 10am - 6pm CET</p>

                <Anchor href="https://wa.me/31208098587" target="_blank">
                  <WhatsAppIcon className="h-6.5 w-6.5" />
                  +31 20 809 8587
                </Anchor>
              </div>
            ) : (
              <div>
                <p className="mb-1 flex gap-2 text-base font-bold">
                  Reach us on WhatsApp after sign up
                  <WhatsAppIcon className="h-6.5 w-6.5" />
                </p>
                <p className="mb-1">Weekdays from 10am - 6pm CET</p>

                <RegisterLink
                  color="primary"
                  appearance="contained"
                  size="small"
                  className="mt-1 w-full md:w-fit"
                >
                  Sign up
                </RegisterLink>
              </div>
            )}
          </div>
          <div className="flex w-full flex-col sm:w-auto">
            <HubspotSubscribe />
          </div>
        </FooterRow>

        {pageBlok ? (
          <ErrorBoundary fallback={null} onError={(error) => logError(error)}>
            <CmsFooter pageBlok={pageBlok} />
          </ErrorBoundary>
        ) : null}

        <FooterRow className="flex flex-col gap-y-9 xl:flex-row xl:justify-between xl:gap-y-0">
          <span className="text-gray-700">
            &copy; {date.getFullYear()} Qogita. All rights reserved.
          </span>
          <ul className="flex flex-col items-start gap-2 divide-gray-300 sm:flex-row sm:items-center sm:gap-0 sm:divide-x">
            <li>
              <FooterLink
                className="whitespace-nowrap text-gray-700 sm:pr-5"
                href="/legal/cookies/"
              >
                Cookies
              </FooterLink>
            </li>
            <li>
              {consent.status === 'loading' ? null : (
                <ConsentModal>
                  <ConsentModalTrigger className="outline-primary-700 whitespace-nowrap text-gray-700 outline-offset-4 sm:px-5">
                    Cookie Settings
                  </ConsentModalTrigger>
                  <CookieSettingsModelContent />
                </ConsentModal>
              )}
            </li>
            <li>
              <FooterLink
                className="whitespace-nowrap text-gray-700 sm:px-5"
                href="/legal/privacy/"
              >
                Privacy Policy
              </FooterLink>
            </li>
            <li>
              <FooterLink
                className="whitespace-nowrap text-gray-700 sm:pl-5"
                href="/legal/terms-buyer/"
              >
                Terms of Use
              </FooterLink>
            </li>
          </ul>
          <div className="flex gap-4 xl:justify-end">
            {showMonduMarketing ? <MonduLogo className="h-[1.125rem]" /> : null}
            <VisaIcon title="Visa" className="h-[1.125rem]" />
            <MasterCardIcon title="Mastercard" className="h-[1.125rem]" />
            <AmexIcon title="American Express" className="h-[1.125rem]" />
            <ApplePayIcon title="Apple Pay" className="h-[1.125rem]" />
            <GooglePayIcon title="Google Pay" className="h-[1.125rem]" />
            <UnionPayIcon title="UnionPay" className="h-[1.125rem]" />
            <IdealIcon title="iDEAL" className="h-[1.125rem]" />
          </div>
        </FooterRow>
      </div>
    </footer>
  )
}

export { Footer }
