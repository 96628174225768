import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import { cn } from '@qogita/ui'
import Link from 'next/link'
import { useActiveCart } from 'src/api/cart-queries'

import { useLoginLink } from '#components/LoginLink'
import { ActiveCartLink } from '#components/pages/CartQidPage/CartLink'
import { useAuthentication } from '#contexts/Authentication'
import { getShortenedValueWithSuffix } from '#lib/number'

export const MastheadCart = (): JSX.Element => {
  const { data: activeCart } = useActiveCart()
  const { isAuthenticated } = useAuthentication()
  const loginLink = useLoginLink()

  if (!isAuthenticated) {
    // If the user is not authenticated, this is a link to login as the user can't view their cart
    // Rather than being a non-functional link
    return (
      <Link
        {...loginLink.getProps({
          className: 'rounded-full px-2 transition-all hover:bg-gray-100',
        })}
      >
        <CartIconWithCount />
      </Link>
    )
  }

  return (
    <ActiveCartLink
      className="rounded-full px-2 transition-all hover:bg-gray-100"
      aria-label="Your cart"
    >
      <CartIconWithCount cartLinesCount={activeCart?.cartlinesCount} />
    </ActiveCartLink>
  )
}

function CartIconWithCount({
  cartLinesCount = 0,
  className,
}: {
  cartLinesCount?: number
  className?: string
}) {
  return (
    <div className={cn('flex items-center', className)}>
      <ShoppingCartIcon className="size-6 fill-current sm:size-8" />
      <span
        className={cn(
          'bg-primary-700 rounded-full px-2 py-0.5 font-mono text-xs text-white transition-opacity',
          {
            'opacity-0': cartLinesCount === 0, // Hide badge when cart is empty but prevent layout shift
          },
        )}
      >
        {getShortenedValueWithSuffix(cartLinesCount, 1)}
      </span>
    </div>
  )
}
