import clsx from 'clsx'
import {
  forwardRef,
  type InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

type NativeCheckboxAttributes = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'style' | 'defaultChecked'
>

type CheckboxProps = NativeCheckboxAttributes & {
  indeterminate?: boolean
  error?: boolean
}

/**
 * @deprecated
 */
export const DeprecatedCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function DeprecatedCheckbox(
    { checked, disabled, indeterminate = false, error, className, ...props },
    externalRef,
  ) {
    const ref = useRef<HTMLInputElement>(null)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(externalRef, () => ref.current!, [ref.current])

    useEffect(() => {
      // Bail early
      if (!ref.current) return

      ref.current.indeterminate = !checked && indeterminate
    }, [ref, checked, indeterminate])

    return (
      <input
        {...props}
        ref={ref}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className={clsx(
          'h-4.5 w-4.5 flex-none rounded border-2 border-gray-300 bg-white',
          'focus-visible:outline-primary-500 focus:ring-0 focus:ring-offset-0 focus-visible:outline-2 focus-visible:outline-offset-1',
          'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-white disabled:checked:bg-gray-200 disabled:indeterminate:bg-gray-200',
          {
            'hover:bg-gray-100':
              !error && !disabled && !checked && !ref.current?.indeterminate,
            'indeterminate:border-primary-700 indeterminate:bg-primary-700 indeterminate:text-primary-700':
              !error,
            'checked:border-primary-700 checked:bg-primary-700 checked:text-primary-700':
              !error,
            'border-error-700 bg-error-100 checked:text-error-700 indeterminate:text-error-700':
              error,
          },
          className,
        )}
      />
    )
  },
)
