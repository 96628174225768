/**
 * @param word String to be capitalized
 * @returns Capitalized string
 */
export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

/**
 * @returns A string without spaces
 */
export const removeSpaces = (content: string): string =>
  content.replaceAll(/\s/g, '')
