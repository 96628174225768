import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import {
  ComponentPropsWithoutRef,
  createContext,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  useContext,
  useState,
} from 'react'

import { Input } from '../Input'
import { cn } from '../utils'

type PasswordFieldContext = {
  type: 'text' | 'password'
}
const PasswordFieldContext = createContext<PasswordFieldContext | undefined>(
  undefined,
)
function usePasswordFieldContext() {
  const context = useContext(PasswordFieldContext)
  if (!context) {
    throw new Error(
      'usePasswordFieldContext must be used within a PasswordField',
    )
  }
  return context
}

/**
 * A wrapper for password fields that provides a button to toggle visibility
 */
const PasswordField = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(function PasswordField({ className, children, ...props }, ref) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  return (
    <PasswordFieldContext.Provider
      value={{ type: isPasswordVisible ? 'text' : 'password' }}
    >
      <div className={cn('relative', className)} ref={ref} {...props}>
        {children}
        <span className="absolute bottom-0 right-0 top-0 grid place-content-center pr-3">
          <button
            type="button"
            aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            <EyeIcon
              className={cn(
                'h-5 w-5 text-gray-400',
                isPasswordVisible ? 'hidden' : 'block',
              )}
            />
            <EyeSlashIcon
              className={cn(
                'h-5 w-5 text-gray-400',
                isPasswordVisible ? 'block' : 'hidden',
              )}
            />
          </button>
        </span>
      </div>
    </PasswordFieldContext.Provider>
  )
})

/**
 * An input field for passwords
 *
 * Consumes context from `<PasswordField>` to determine whether the input should be
 * visible (type="text") or hidden (type="password")
 */
const PasswordFieldInput = forwardRef<
  ElementRef<typeof Input>,
  Omit<ComponentPropsWithoutRef<typeof Input>, 'type'>
>(function PasswordFieldInput({ className, ...props }, ref) {
  const { type } = usePasswordFieldContext()
  return (
    <Input
      className={cn('w-full pr-11', className)}
      {...props}
      type={type}
      ref={ref}
    />
  )
})

export { PasswordField, PasswordFieldInput }
