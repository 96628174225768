import clsx from 'clsx'

import { SvgAttr as SvgAttribute } from '../utils/icon.interface'

type QogitaLogoWordmarkProps = SvgAttribute & {
  theme: keyof typeof QOGITA_TEXT_THEME
}

export const QOGITA_TEXT_THEME = {
  white: 'fill-white',
  primary: 'fill-primary-700',
  gray: 'fill-gray-200',
  black: 'fill-gray-900',
}

export const SELLERS_TEXT_THEME = {
  white: 'fill-white',
  primary: 'fill-primary-700',
  gray: 'fill-primary-700',
  black: 'fill-primary-700',
}

export function QogitaSellersLogoWordmark({
  theme,
  className,
  ...props
}: QogitaLogoWordmarkProps) {
  const qogitaTextTheme = QOGITA_TEXT_THEME[theme]
  const sellersTextTheme = SELLERS_TEXT_THEME[theme]

  return (
    <svg
      {...props}
      className={clsx('fill-transparent', className)}
      viewBox="0 0 145 53"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={qogitaTextTheme}
        d="M143.91 27.6512C143.083 27.6512 142.669 27.1802 142.669 26.2437V17.5757C142.669 14.6708 141.828 12.4537 140.147 10.9246C138.466 9.39544 136.179 8.63086 133.285 8.63086C131.397 8.63086 129.796 8.93525 128.483 9.54404C127.277 10.0719 126.203 10.8883 125.345 11.9294C124.58 12.8851 124.003 13.9924 123.647 15.1867L128.782 17.013C128.95 16.0207 129.411 15.1105 130.1 14.4137C130.779 13.7414 131.812 13.4059 133.199 13.4071C134.615 13.4071 135.647 13.7576 136.296 14.4587C136.944 15.1633 137.214 16.0639 137.271 17.155C137.299 17.7285 137.053 18.4655 135.489 18.4655H131.244C129.74 18.4381 128.246 18.7164 126.841 19.2852C125.635 19.7702 124.589 20.6173 123.832 21.7227C123.109 22.8013 122.747 24.1363 122.747 25.7278C122.747 27.3192 123.109 28.6704 123.832 29.7813C124.568 30.9021 125.588 31.7795 126.775 32.3124C128.104 32.9086 129.536 33.2038 130.979 33.1788C132.507 33.1788 134.411 32.9433 135.296 32.4759C136.18 32.0086 136.824 31.4693 137.243 30.8581C137.577 30.4062 137.851 29.9093 138.06 29.3805C138.285 28.8082 138.481 28.2237 138.648 27.6296H139.157C139.157 27.6296 139.086 28.1887 139.025 29.2259C139.025 30.1606 139.093 30.8437 139.743 31.4747C140.393 32.1056 141.008 32.316 142.1 32.316H144.569V27.6422L143.91 27.6512ZM137.271 23.337C137.271 24.8685 136.776 26.0951 135.786 27.0166C134.797 27.9382 133.524 28.399 131.968 28.399C130.786 28.399 129.886 28.1336 129.268 27.6027C128.971 27.3604 128.731 27.0475 128.57 26.6893C128.408 26.3311 128.328 25.9377 128.337 25.5408C128.321 25.161 128.394 24.7829 128.548 24.4397C128.703 24.0965 128.935 23.7987 129.223 23.5725C129.813 23.1051 130.624 22.8714 131.657 22.8714H137.279L137.271 23.337Z"
      />
      <path
        className={qogitaTextTheme}
        d="M113.292 15.086H120.019V10.2865H113.292V3.17578H107.058V10.2865H100.925V15.086H107.058V28.0145C107.058 29.5836 107.564 30.8375 108.577 31.7762C109.589 32.7148 110.897 33.183 112.501 33.1806H119.425V28.3811H114.681C113.757 28.3811 113.298 27.9171 113.298 26.9944L113.292 15.086Z"
      />
      <path
        className={qogitaTextTheme}
        d="M97.4275 9.8916H91.7085V32.3883H97.4275V9.8916Z"
      />
      <path
        className={qogitaTextTheme}
        d="M94.568 0.00187073C93.5995 -0.0219052 92.6609 0.337026 91.9557 1.00072C91.2452 1.66546 90.8893 2.54249 90.8882 3.63183C90.887 4.72116 91.2429 5.59762 91.9557 6.2612C92.6772 6.90463 93.6105 7.26024 94.5775 7.26024C95.5446 7.26024 96.4778 6.90463 97.1993 6.2612C97.8959 5.59646 98.2442 4.72 98.2442 3.63183C98.2442 2.54365 97.8959 1.66662 97.1993 1.00072C96.8485 0.668796 96.4353 0.409629 95.9837 0.238186C95.532 0.066744 95.0508 -0.0135744 94.568 0.00187073Z"
      />
      <path
        className={qogitaTextTheme}
        d="M81.4552 9.28322V10.1691C81.4552 11.8187 81.9008 12.352 82.121 12.6692L81.6753 13.0147C81.6753 13.0147 80.2896 11.5529 80.0009 11.2021C79.4362 10.5394 78.8876 10.1691 78.211 9.73681C77.0942 9.02808 75.5046 8.63119 73.3665 8.63119C71.5106 8.61653 69.6842 9.08677 68.0744 9.99372C66.4365 10.9243 65.0936 12.2829 64.1952 13.9183C63.2221 15.6275 62.7361 17.6828 62.7373 20.0843V20.8231C62.7373 23.2245 63.2233 25.2798 64.1952 26.989C65.0936 28.6245 66.4365 29.9831 68.0744 30.9136C69.684 31.8212 71.5105 32.2921 73.3665 32.2779C74.8099 32.2779 76.0008 32.1084 76.939 31.7694C77.7688 31.4939 78.536 31.0624 79.198 30.499C79.4498 30.288 79.6823 30.0556 79.8926 29.8045C80.1813 29.4501 81.5743 27.9636 81.5743 27.9636L82.0199 28.3179C81.7998 28.6333 81.3613 29.1666 81.3613 30.8162V33.5342C81.3613 34.4567 80.9223 34.918 80.0442 34.918H69.4223V39.9995H82.1137C83.6775 39.9995 84.9315 39.53 85.8757 38.5909C86.82 37.6519 87.2903 36.3974 87.2867 34.8276V9.28322H81.4552ZM81.4552 20.6902C81.4552 22.7526 80.8435 24.3532 79.6202 25.4919C78.3969 26.6306 76.8764 27.2005 75.0589 27.2017C73.2402 27.2017 71.7197 26.6317 70.4976 25.4919C69.2755 24.352 68.6638 22.7514 68.6626 20.6902V20.2295C68.6626 18.1352 69.2743 16.5258 70.4976 15.4013C71.7209 14.2768 73.2414 13.7146 75.0589 13.7146C76.9089 13.7146 78.4378 14.2768 79.6454 15.4013C80.8531 16.5258 81.4546 18.1352 81.4498 20.2295L81.4552 20.6902Z"
      />
      <path
        className={qogitaTextTheme}
        d="M54.0133 10.0347C52.1551 9.09999 50.0621 8.63206 47.7342 8.63086C45.4064 8.62966 43.3134 9.0976 41.4552 10.0347C39.6275 10.946 38.1038 12.3615 37.0669 14.1112C35.9971 15.8931 35.4617 18.0327 35.4604 20.5299V21.2794C35.4604 23.7803 35.9959 25.9198 37.0669 27.6981C38.1038 29.4479 39.6275 30.8634 41.4552 31.7747C43.3134 32.7117 45.4064 33.1803 47.7342 33.1803C50.0621 33.1803 52.1551 32.7117 54.0133 31.7747C55.8418 30.8639 57.3663 29.4483 58.4034 27.6981C59.4744 25.9186 60.0099 23.7791 60.0099 21.2794V20.5299C60.0099 18.0315 59.4744 15.8919 58.4034 14.1112C57.3663 12.361 55.8418 10.9454 54.0133 10.0347ZM54.0622 21.1392C54.0622 23.3273 53.4633 25.0049 52.2656 26.1721C51.0679 27.3392 49.5586 27.9252 47.7379 27.9299C45.9123 27.9299 44.403 27.344 43.2101 26.1721C42.0172 25.0001 41.4196 23.3225 41.4171 21.1392V20.6719C41.4171 18.4862 42.0148 16.8086 43.2101 15.6391C44.4055 14.4695 45.9147 13.8842 47.7379 13.883C49.5948 13.883 51.1131 14.4683 52.2928 15.6391C53.4724 16.8098 54.0634 18.4874 54.0658 20.6719L54.0622 21.1392Z"
      />
      <path
        className={qogitaTextTheme}
        d="M33.5051 33.0151C31.78 31.2854 29.6586 30.0072 27.327 29.2926L27.5124 28.6722C27.9957 28.7938 28.5013 28.7957 28.9856 28.6777C29.4698 28.5597 29.9183 28.3254 30.2925 27.9949C31.8748 26.5696 32.9645 23.247 32.9645 19.7227C32.959 17.2483 32.4022 14.8065 31.3349 12.5763C30.2676 10.3461 28.7168 8.38396 26.7963 6.83377C24.8758 5.28358 22.6342 4.1846 20.2358 3.61739C17.8374 3.05018 15.343 3.0291 12.9355 3.55572C6.71622 4.86721 1.68794 9.91841 0.380249 16.1639C-0.145816 18.5828 -0.125796 21.0894 0.438836 23.4995C1.00347 25.9097 2.09838 28.1622 3.64321 30.0919C5.18804 32.0216 7.14356 33.5794 9.36625 34.6509C11.5889 35.7225 14.0224 36.2806 16.4879 36.2843C20.2926 36.2843 22.9303 35.1693 24.3135 33.9267C25.6967 32.6842 25.5766 30.6041 25.5766 30.6041L26.1274 30.4748L26.1566 30.5661C26.8794 32.8382 28.1334 34.9035 29.8137 36.5893L33.1979 39.9999L36.8241 36.3584L33.5051 33.0151ZM16.4879 30.3645C14.3942 30.3642 12.3475 29.7404 10.6068 28.5721C8.86608 27.4037 7.5094 25.7433 6.70832 23.8007C5.90724 21.8581 5.69773 19.7206 6.10628 17.6585C6.51483 15.5964 7.5231 13.7022 9.00359 12.2154C10.4841 10.7287 12.3703 9.71617 14.4238 9.3059C16.4773 8.89563 18.6058 9.10602 20.5402 9.91048C22.4747 10.7149 24.1281 12.0773 25.2915 13.8254C26.455 15.5735 27.0761 17.6287 27.0765 19.7313C27.0742 22.5507 25.9579 25.254 23.9726 27.2476C21.9874 29.2412 19.2955 30.3622 16.4879 30.3645Z"
      />
      <path
        className={sellersTextTheme}
        d="M96.7744 52.9899C96.1275 52.9899 95.5363 52.9118 94.9898 52.7668C94.4432 52.6218 93.9859 52.4098 93.5732 52.1421C93.1605 51.8745 92.8259 51.5733 92.5582 51.2387C92.2905 50.9041 92.112 50.5583 92.0005 50.2014L94.0194 49.5879C94.1755 50.0229 94.4767 50.4021 94.9228 50.7256C95.369 51.0491 95.9267 51.2164 96.5848 51.2275C97.3544 51.2275 97.9567 51.0714 98.414 50.7591C98.8602 50.4467 99.0833 50.034 99.0833 49.521C99.0833 49.0525 98.8937 48.6733 98.5144 48.3721C98.1352 48.0709 97.6333 47.8479 96.9863 47.6806L95.4582 47.2902C94.8782 47.134 94.354 46.9221 93.8967 46.6209C93.4282 46.3309 93.0601 45.9628 92.8036 45.5167C92.5359 45.0705 92.402 44.5463 92.402 43.944C92.402 42.8063 92.7813 41.9139 93.5397 41.2782C94.2982 40.6424 95.3801 40.3301 96.7744 40.3301C97.5663 40.3301 98.2579 40.4528 98.8602 40.687C99.4514 40.9212 99.9533 41.2559 100.344 41.6686C100.734 42.0813 101.024 42.5497 101.214 43.074L99.2283 43.6986C99.0498 43.2301 98.7487 42.8509 98.3025 42.5386C97.8675 42.2374 97.3209 42.0813 96.674 42.0813C96.0271 42.0813 95.4694 42.2374 95.0901 42.5497C94.7109 42.862 94.5101 43.297 94.5101 43.8659C94.5101 44.3232 94.6551 44.669 94.9563 44.9255C95.2575 45.1709 95.6701 45.3605 96.1832 45.4944L97.7113 45.8625C98.8156 46.1302 99.6856 46.5875 100.299 47.2455C100.913 47.9036 101.225 48.6175 101.225 49.4206C101.225 50.1233 101.058 50.7367 100.712 51.2721C100.366 51.8075 99.8641 52.2314 99.1948 52.5325C98.5256 52.8337 97.7113 52.9899 96.7632 52.9899H96.7744Z"
      />
      <path
        className={sellersTextTheme}
        d="M107.181 52.9899C106.322 52.9899 105.553 52.7891 104.883 52.3987C104.214 52.0083 103.69 51.4729 103.311 50.7814C102.931 50.0898 102.742 49.2979 102.742 48.4056C102.742 47.5133 102.931 46.7102 103.322 46.0298C103.712 45.3382 104.247 44.8028 104.917 44.4124C105.586 44.0221 106.367 43.8213 107.259 43.8213C108.04 43.8213 108.743 44.0221 109.389 44.4236C110.025 44.8251 110.527 45.4052 110.906 46.1636C111.274 46.9221 111.464 47.8256 111.464 48.874H109.468C109.479 48.1156 109.389 47.491 109.211 47.0002C109.033 46.5094 108.765 46.1413 108.419 45.9071C108.073 45.6728 107.672 45.5502 107.192 45.5502C106.701 45.5502 106.278 45.6617 105.932 45.8848C105.586 46.1079 105.318 46.4313 105.129 46.8552C104.939 47.279 104.85 47.8144 104.85 48.4613C104.85 49.0302 104.961 49.521 105.184 49.9337C105.407 50.3464 105.709 50.6587 106.099 50.8706C106.478 51.0825 106.913 51.1941 107.382 51.1941C107.906 51.1941 108.33 51.0714 108.676 50.8371C109.021 50.6029 109.278 50.2906 109.468 49.9225L111.286 50.6921C111.04 51.1606 110.728 51.5622 110.349 51.9191C109.969 52.2649 109.512 52.5325 108.988 52.7222C108.453 52.9118 107.861 53.001 107.203 53.001L107.181 52.9899ZM104.024 48.874L104.047 47.3125H110.416V48.874H104.024Z"
      />
      <path
        className={sellersTextTheme}
        d="M113.082 52.7773L113.104 40.5303H115.089L115.067 52.7773H113.082Z"
      />
      <path
        className={sellersTextTheme}
        d="M117.276 52.7773L117.298 40.5303H119.284L119.261 52.7773H117.276Z"
      />
      <path
        className={sellersTextTheme}
        d="M125.529 52.9899C124.67 52.9899 123.901 52.7891 123.231 52.3987C122.562 52.0083 122.038 51.4729 121.659 50.7814C121.279 50.0898 121.09 49.2979 121.09 48.4056C121.09 47.5133 121.279 46.7102 121.67 46.0298C122.06 45.3382 122.596 44.8028 123.265 44.4124C123.934 44.0221 124.715 43.8213 125.607 43.8213C126.388 43.8213 127.091 44.0221 127.738 44.4236C128.373 44.8251 128.875 45.4052 129.255 46.1636C129.623 46.9221 129.812 47.8256 129.812 48.874H127.816C127.827 48.1156 127.738 47.491 127.559 47.0002C127.381 46.5094 127.113 46.1413 126.767 45.9071C126.421 45.6728 126.02 45.5502 125.54 45.5502C125.05 45.5502 124.626 45.6617 124.28 45.8848C123.934 46.1079 123.666 46.4313 123.477 46.8552C123.287 47.279 123.198 47.8144 123.198 48.4613C123.198 49.0302 123.309 49.521 123.533 49.9337C123.756 50.3464 124.057 50.6587 124.447 50.8706C124.826 51.0825 125.261 51.1941 125.73 51.1941C126.254 51.1941 126.678 51.0714 127.024 50.8371C127.37 50.6029 127.626 50.2906 127.816 49.9225L129.634 50.6921C129.388 51.1606 129.076 51.5622 128.697 51.9191C128.318 52.2649 127.86 52.5325 127.336 52.7222C126.801 52.9118 126.21 53.001 125.551 53.001L125.529 52.9899ZM122.373 48.874L122.395 47.3125H128.764V48.874H122.373Z"
      />
      <path
        className={sellersTextTheme}
        d="M131.43 52.7786V44.0339H133.416V52.7786H131.43ZM132.735 48.1274C132.735 47.1793 132.914 46.4097 133.282 45.7962C133.65 45.1939 134.118 44.7366 134.709 44.4354C135.289 44.1343 135.903 43.9893 136.539 43.9893V45.8631C135.992 45.8631 135.49 45.9412 135.011 46.0862C134.531 46.2312 134.141 46.4654 133.851 46.8001C133.561 47.1235 133.404 47.5585 133.404 48.0939L132.735 48.1274Z"
      />
      <path
        className={sellersTextTheme}
        d="M141.078 52.9899C140.486 52.9899 139.94 52.9006 139.449 52.7222C138.958 52.5437 138.523 52.2983 138.178 51.9749C137.821 51.6514 137.553 51.2833 137.363 50.8483L139.081 50.101C139.259 50.4133 139.516 50.681 139.873 50.8929C140.219 51.1048 140.609 51.2164 141.033 51.2164C141.479 51.2164 141.847 51.1383 142.126 50.9933C142.405 50.8483 142.539 50.6364 142.539 50.3575C142.539 50.0787 142.427 49.8779 142.215 49.7329C142.003 49.5879 141.702 49.4652 141.334 49.376L140.498 49.1529C139.65 48.941 138.992 48.6064 138.512 48.149C138.044 47.6917 137.809 47.1563 137.809 46.5652C137.809 45.6952 138.099 45.0148 138.679 44.5351C139.259 44.0555 140.085 43.8213 141.156 43.8213C141.702 43.8213 142.193 43.8994 142.65 44.0555C143.108 44.2117 143.498 44.4348 143.833 44.7248C144.167 45.0148 144.39 45.3494 144.524 45.7286L142.873 46.4648C142.751 46.1748 142.528 45.9517 142.204 45.7955C141.869 45.6394 141.513 45.5725 141.111 45.5725C140.709 45.5725 140.419 45.6617 140.185 45.829C139.962 45.9963 139.851 46.2306 139.851 46.5206C139.851 46.699 139.951 46.8552 140.152 47.0002C140.353 47.1452 140.631 47.2679 140.999 47.3571L142.048 47.6025C142.628 47.7475 143.096 47.9594 143.476 48.2606C143.855 48.5617 144.123 48.8852 144.301 49.2533C144.48 49.6214 144.569 49.9894 144.569 50.3687C144.569 50.8929 144.424 51.3502 144.123 51.7518C143.821 52.1533 143.409 52.4545 142.896 52.6664C142.371 52.8783 141.78 52.9899 141.111 52.9899H141.078Z"
      />
    </svg>
  )
}
