import { User } from '@qogita/canary-types'
import { logError } from '@qogita/logging'
import Script from 'next/script'

import { useConsent } from '#contexts/Consent'
import { environment } from '#lib/environment.mjs'

export const MetaScript = () => {
  const { consent } = useConsent()

  const isConsentAccepted =
    consent.status !== 'loading' && consent.value.marketing

  const shouldLoadScript = isConsentAccepted

  return shouldLoadScript ? (
    <Script id="meta-pixel">
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${environment.NEXT_PUBLIC_META_PIXEL_ID}');
        fbq('track', 'PageView');
      `}
    </Script>
  ) : null
}

export const metaIdentifyUser = (
  user: Pick<User, 'qid' | 'email' | 'account' | 'phone'>,
) => {
  if (!window.fbq) return

  try {
    // Params here are meta advanced matching API values
    // https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching/
    window.fbq('init', environment.NEXT_PUBLIC_META_PIXEL_ID, {
      em: user.email,
      fn: user.account,
      ph: user.phone,
      external_id: user.qid,
    })
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}

export const metaEvent: MetaEvent = (eventName, parameters) => {
  if (!window.fbq) return

  try {
    window.fbq('track', eventName, parameters)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}

export const metaCustomEvent = (
  eventName: string,
  parameters: facebook.Pixel.CustomParameters,
) => {
  if (!window.fbq) return

  try {
    window.fbq('trackCustom', eventName, parameters)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}

// This allows us to get proper type checking on the Meta Pixel Standard Events
// https://developers.facebook.com/docs/meta-pixel/reference
// This is the subset of the standard events we currently use
// but can be expanded as needed
interface MetaEvent {
  (eventName: 'AddToCart', parameters: facebook.Pixel.AddToCartParameters): void
  (
    eventName: 'ViewContent',
    parameters: facebook.Pixel.ViewContentParameters,
  ): void
  (eventName: 'Search', parameters: facebook.Pixel.SearchParameters): void
  (
    eventName: 'InitiateCheckout',
    parameters: facebook.Pixel.InitiateCheckoutParameters,
  ): void
  (eventName: 'Purchase', parameters: facebook.Pixel.PurchaseParameters): void
  (
    eventName: 'CompleteRegistration',
    parameters: facebook.Pixel.CompleteRegistrationParameters,
  ): void
}
