// Components composed ready as field controls
export * from './DeprecatedControls'

// Internal building blocks
import { DeprecatedErrorMessage } from './DeprecatedErrorMessage'
import { DeprecatedField as FieldRoot } from './DeprecatedField'
import { DeprecatedFieldDescription } from './DeprecatedFieldDescription'
import {
  DeprecatedFormLabel,
  DeprecatedFormLegend,
} from './DeprecatedFieldLabel'

export const DeprecatedField = Object.assign(FieldRoot, {
  Label: DeprecatedFormLabel,
  Legend: DeprecatedFormLegend,
  Description: DeprecatedFieldDescription,
  Error: DeprecatedErrorMessage,
})

// Composable tool (internal/external)
export * from './DeprecatedFormField'
export * from './DeprecatedFormFieldset'
export * from './DeprecatedPasswordField'
export * from './DeprecatedPhoneField'
export * from './useDeprecatedFormField'
