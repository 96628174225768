import clsx from 'clsx'
import type { ComponentPropsWithoutRef } from 'react'

import { PageErrorCodeOptions } from './page-error.interface'

type PageErrorCodeProps = Omit<ComponentPropsWithoutRef<'h2'>, 'children'> & {
  httpCode: PageErrorCodeOptions
}

export function PageErrorCode({
  className,
  httpCode,
  ...props
}: PageErrorCodeProps) {
  return (
    <h2
      {...props}
      className={clsx(
        'text-primary-500 text-[175px] font-extrabold leading-none md:text-[250px]',
        className,
      )}
    >
      {httpCode}
    </h2>
  )
}
