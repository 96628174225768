import { Dialog } from '@headlessui/react'
import { SparklesIcon } from '@heroicons/react/16/solid'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCanaryClient } from '@qogita/canary-client'
import {
  BrandSuggestion,
  CategorySuggestion,
  VariantSuggestion,
} from '@qogita/canary-types'
import { logWarning } from '@qogita/logging'
import {
  Button,
  cn,
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  FormFieldController,
  Input,
} from '@qogita/ui'
import { useQuery } from '@tanstack/react-query'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { getVariantQueries } from 'src/api/variant-queries'
import { z } from 'zod'

import { Loader } from '#components/Loader'
import { NextAnchor } from '#components/NextAnchor'
import { useLocalisationPreferences } from '#hooks/api/useLocalisationPreferences'
import useDebounce from '#hooks/useDebounce'
import { useQueryParams } from '#hooks/useQueryParams'
import { useTrackEvent } from '#lib/report/tracking'
import { getUrlPathname } from '#lib/url'

import { useUser } from '../../api/user-queries'

const querySchema = z.object({
  query: z.string().optional().default(''),
})

const TextSuggestionResult = ({
  textHighlighted,
}: {
  textHighlighted: string
}) => (
  <span
    className="block py-3 text-sm font-bold text-gray-900 md:py-0 [&>em]:text-sm [&>em]:not-italic"
    dangerouslySetInnerHTML={{
      __html: textHighlighted,
    }}
    translate="no"
  />
)
type OptionGroupProps = {
  title: string
  children: React.ReactNode
}

const ProductSuggestionResult = (product: VariantSuggestion) => {
  const { name, nameHighlighted, gtin, imageUrl } = product
  return (
    <div className="flex gap-2">
      <Image
        src={getUrlPathname(imageUrl)}
        alt={name}
        width={70}
        height={70}
        className="shrink-0 bg-gray-200"
      />
      <div className="flex flex-col justify-center gap-2">
        <span
          className="line-clamp-2 text-sm font-bold text-gray-900 [&>em]:text-sm [&>em]:not-italic"
          dangerouslySetInnerHTML={{
            __html: nameHighlighted,
          }}
        />
        <span>{gtin}</span>
      </div>
    </div>
  )
}

const OptionGroup = ({ title, children }: OptionGroupProps) => {
  return (
    <div className="flex flex-col gap-2 border-b border-gray-100 p-2 last:border-b-0">
      <p className="my-2 px-2 text-xs text-gray-500">{title}</p>
      {children}
    </div>
  )
}

const SuggestionSectionHeading = ({
  children,
}: {
  children: React.ReactNode
}) => <figcaption className="my-4 text-xs text-gray-500">{children}</figcaption>

const SuggestionResultList = ({ children }: { children: React.ReactNode }) => (
  <ul className="flex flex-col gap-y-2">{children}</ul>
)

const SuggestionFigure = ({ children }: { children: React.ReactNode }) => (
  <figure className="px-4">{children}</figure>
)

const StickyHeader = ({ children }: { children: React.ReactNode }) => {
  // Since we don't get a :stuck pseudo class, we have to use an intersection observer
  // https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
  const searchInputContainerRef = useRef<HTMLDivElement>(null)
  const [isStuck, setIsStuck] = useState(false)

  // This has been borrowed from CartQidPage
  // If we use this again its worth abstracting into a shared hook
  useEffect(() => {
    if (!searchInputContainerRef.current) return
    const elementToObserve = searchInputContainerRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry) return
        setIsStuck(entry.intersectionRatio < 1)
      },
      { threshold: 1 },
    )

    observer.observe(elementToObserve)

    return () => {
      observer.unobserve(elementToObserve)
    }
  }, [])

  return (
    <div
      ref={searchInputContainerRef}
      className={cn(
        'sticky -top-px z-10 flex bg-white px-4 py-2 before:absolute before:inset-0 before:-z-10 before:opacity-0 before:shadow-md before:transition-opacity',
        {
          'before:opacity-100': isStuck,
        },
      )}
    >
      {children}
    </div>
  )
}

const MobileSearch = ({ className }: { className?: string }) => {
  const { data: user } = useUser()
  const [{ query: urlSearchQuery }] = useQueryParams({
    validationSchema: querySchema,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const searchButtonRef = useRef<HTMLButtonElement>(null)

  const { push } = useRouter()
  const { trackSearchSuggestionClicked, trackSuggestionsSearched } =
    useTrackEvent()

  const [query, setQuery] = useState(urlSearchQuery || '')
  const debouncedQuery = useDebounce(query, 300)
  const { country } = useLocalisationPreferences()
  const canaryClient = useCanaryClient()
  const variantQueries = getVariantQueries(canaryClient)
  const searchSuggestionsQuery = useQuery({
    ...variantQueries.searchSuggestions({ query: debouncedQuery, country }),
    enabled: Boolean(debouncedQuery),
  })

  const isBrandsSuggestionsAvailable =
    searchSuggestionsQuery.data?.brands &&
    searchSuggestionsQuery.data?.brands?.length > 0

  const isCategoriesSuggestionsAvailable =
    searchSuggestionsQuery.data?.categories &&
    searchSuggestionsQuery.data?.categories?.length > 0

  const isProductsSuggestionsAvailable =
    searchSuggestionsQuery.data?.products &&
    searchSuggestionsQuery.data?.products?.length > 0

  const isEmptyResults =
    !isBrandsSuggestionsAvailable &&
    !isCategoriesSuggestionsAvailable &&
    !isProductsSuggestionsAvailable &&
    searchSuggestionsQuery.isFetched

  const handleModalClose = () => {
    flushSync(() => {
      setModalOpen(false)
    })
    searchButtonRef.current?.focus()
  }

  useEffect(() => {
    if (debouncedQuery) {
      trackSuggestionsSearched({
        searchTerm: debouncedQuery,
      })
    }
  }, [debouncedQuery, trackSuggestionsSearched])

  return (
    <div className={className}>
      <button
        className={cn(
          'flex w-full justify-between rounded border px-3 py-2 text-start',
          {
            'text-gray-500': query.length === 0,
            'text-gray-900': query.length > 0,
          },
        )}
        onClick={() => {
          setModalOpen((modalOpen) => !modalOpen)
          searchInputRef.current?.focus()
        }}
        ref={searchButtonRef}
        aria-label="Search catalog"
        translate="no"
      >
        {query.length > 0 ? query : 'Search'}
        <MagnifyingGlassIcon className="mr-2 h-6 w-6 text-gray-400" />
      </button>
      <Dialog
        className="relative"
        open={modalOpen}
        static
        onClose={handleModalClose}
        aria-label="Search catalog"
      >
        <Dialog.Panel
          className={cn(
            'fixed top-0 z-10 flex h-dvh w-dvw flex-col overflow-auto bg-white',
            {
              // We control visiblity here with CSS because
              // iOS only opens the keyboard if an input is focused as part of a user action. (e.g a click event)
              // We need a reference to the input so that we can focus it inside the click handler.
              // If we conditionally render the modal content then the input does not exist at the point the button is clicked.
              // This means we can't conditionally render it and need to control visiblity through CSS.
              invisible: !modalOpen,
              visible: modalOpen,
            },
          )}
        >
          <StickyHeader>
            <div className="relative mr-2 flex-1">
              <form
                action="."
                onSubmit={(event) => {
                  event.preventDefault()
                  setModalOpen(false)
                  push(`/products?query=${query}`)
                }}
              >
                <Input
                  type="search"
                  placeholder="Search"
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value)
                  }}
                  ref={searchInputRef}
                />
              </form>
              <div className="absolute right-3 top-0 flex h-full items-center justify-center">
                {searchSuggestionsQuery.isFetching ? (
                  <Loader className="[&>svg]:fill-primary-700 h-6 w-6" />
                ) : query ? (
                  <>
                    {query.length > 0 ? (
                      <button
                        onClick={() => {
                          setQuery('')
                          searchInputRef.current?.focus()
                        }}
                        type="button"
                      >
                        <XMarkIcon className="h-6 w-6 text-gray-400" />
                      </button>
                    ) : null}
                  </>
                ) : (
                  <MagnifyingGlassIcon className="h-6 w-6 text-gray-400" />
                )}
              </div>
            </div>
            <Button
              color="primary"
              appearance="text"
              onClick={handleModalClose}
            >
              Close
            </Button>
          </StickyHeader>
          {query.length > 0 ? (
            <>
              <Link
                href={{
                  pathname: '/products',
                  query: {
                    query,
                  },
                }}
                onClick={() => {
                  setModalOpen(false)
                  trackSearchSuggestionClicked({
                    query,
                    suggestion: {
                      type: 'query',
                    },
                    userShowOnlyTraceableStock:
                      user?.showOnlyTraceableStock ?? false,
                  })
                }}
                className="my-2 px-4 py-4"
              >
                <MagnifyingGlassIcon className="float-left mr-2 inline h-6 w-6 text-gray-400" />
                <span className="clearfix leading-6">
                  Search for&nbsp;
                  <em className="text-sm font-bold not-italic" translate="no">
                    &quot;{query}&quot;
                  </em>
                </span>
              </Link>
              {isEmptyResults ? (
                <div className="text-center">
                  <div className="mt-2 flex justify-center px-2 py-3">
                    No results found.
                  </div>
                  <div className={comboboxOptionStyles}>
                    <div className="flex flex-row items-center justify-center gap-2">
                      <SparklesIcon className="text-primary-700 h-4 w-4" />
                      <NextAnchor
                        className="text-primary-700 whitespace-nowrap text-sm underline"
                        href="/products/request/"
                      >
                        Request a product or brand{' '}
                      </NextAnchor>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="divide-y pb-6">
                    {isProductsSuggestionsAvailable ? (
                      <SuggestionFigure>
                        <SuggestionSectionHeading>
                          Products
                        </SuggestionSectionHeading>
                        <SuggestionResultList>
                          {searchSuggestionsQuery.data?.products?.map(
                            (product) => (
                              <li key={product.gtin}>
                                <Link
                                  onClick={() => {
                                    setModalOpen(false)
                                    trackSearchSuggestionClicked({
                                      query,
                                      suggestion: {
                                        type: 'product',
                                        name: product.name,
                                        gtin: product.gtin,
                                      },
                                      userShowOnlyTraceableStock:
                                        user?.showOnlyTraceableStock ?? false,
                                    })
                                  }}
                                  href={`/products/${product.fid}/${product.slug}`}
                                  className="block py-3"
                                >
                                  <ProductSuggestionResult {...product} />
                                </Link>
                              </li>
                            ),
                          )}
                        </SuggestionResultList>
                      </SuggestionFigure>
                    ) : null}
                    {isBrandsSuggestionsAvailable ? (
                      <SuggestionFigure>
                        <SuggestionSectionHeading>
                          Brands
                        </SuggestionSectionHeading>
                        <SuggestionResultList>
                          {searchSuggestionsQuery.data?.brands?.map((brand) => (
                            <li key={brand.name}>
                              <Link
                                onClick={() => {
                                  setModalOpen(false)
                                  trackSearchSuggestionClicked({
                                    query,
                                    suggestion: {
                                      type: 'brand',
                                      name: brand.name,
                                    },
                                    userShowOnlyTraceableStock:
                                      user?.showOnlyTraceableStock ?? false,
                                  })
                                }}
                                href={{
                                  pathname: '/products',
                                  query: {
                                    brand_name: brand.name,
                                  },
                                }}
                              >
                                <TextSuggestionResult
                                  textHighlighted={brand.nameHighlighted}
                                />
                              </Link>
                            </li>
                          ))}
                        </SuggestionResultList>
                      </SuggestionFigure>
                    ) : null}
                    {isCategoriesSuggestionsAvailable ? (
                      <SuggestionFigure>
                        <SuggestionSectionHeading>
                          Categories
                        </SuggestionSectionHeading>
                        <ul>
                          {searchSuggestionsQuery.data?.categories?.map(
                            (category) => (
                              <li key={category.name}>
                                <Link
                                  onClick={() => {
                                    setModalOpen(false)
                                    trackSearchSuggestionClicked({
                                      query,
                                      suggestion: {
                                        type: 'category',
                                        name: category.name,
                                      },
                                      userShowOnlyTraceableStock:
                                        user?.showOnlyTraceableStock ?? false,
                                    })
                                  }}
                                  href={`/categories/${category.urlPath}`}
                                >
                                  <TextSuggestionResult
                                    textHighlighted={category.nameHighlighted}
                                  />
                                </Link>
                              </li>
                            ),
                          )}
                        </ul>
                      </SuggestionFigure>
                    ) : null}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="flex items-center justify-center py-10">
              Type something to search
            </div>
          )}
        </Dialog.Panel>
      </Dialog>
    </div>
  )
}

const searchFormSchema = z.object({
  selectedSearchSuggestion: z.string().optional(),
})

const comboboxOptionStyles =
  'text-sm text-gray-900 ui-active:bg-gray-100 ui-active:text-gray-900 rounded p-2'

const DesktopSearch = ({ className }: { className?: string }) => {
  const { data: user } = useUser()
  const [{ query: searchQuery }, setQueryParams] = useQueryParams({
    validationSchema: querySchema,
  })
  const { trackSearchSuggestionClicked, trackSuggestionsSearched } =
    useTrackEvent()
  const inputRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState(searchQuery)
  const debouncedQuery = useDebounce(query, 300)
  const { country } = useLocalisationPreferences()
  const canaryClient = useCanaryClient()
  const variantQueries = getVariantQueries(canaryClient)
  const {
    data: searchSuggestions,
    isFetching,
    isFetched,
  } = useQuery({
    ...variantQueries.searchSuggestions({
      query: debouncedQuery,
      country,
    }),
    enabled: Boolean(debouncedQuery),
  })
  const { push } = useRouter()

  const form = useForm({
    defaultValues: {
      selectedSearchSuggestion: 'empty',
    },
    resolver: zodResolver(searchFormSchema),
  })

  const querySearch = () => {
    if (query) {
      trackSearchSuggestionClicked({
        query,
        suggestion: {
          type: 'query',
        },
        userShowOnlyTraceableStock: user?.showOnlyTraceableStock ?? false,
      })
    }

    setQueryParams(
      {
        query,
      },
      { navigationType: 'push', pathname: '/products/' },
    )
  }

  const {
    products = [],
    brands = [],
    categories = [],
  } = searchSuggestions ?? {}

  const handleSubmit = form.handleSubmit(
    ({ selectedSearchSuggestion }) => {
      const [type, index] = selectedSearchSuggestion.split('-')
      const optionIndex = index as unknown as number

      switch (type) {
        case 'query':
          return querySearch()
        case 'product': {
          const productData = products[optionIndex]
          if (!productData) return
          const { name, gtin, fid, slug } = productData

          trackSearchSuggestionClicked({
            query,
            suggestion: {
              type: 'product',
              name,
              gtin,
            },
            userShowOnlyTraceableStock: user?.showOnlyTraceableStock ?? false,
          })

          return push(`/products/${fid}/${slug}`)
        }
        case 'brand': {
          const brandData = brands[optionIndex]
          if (!brandData) return
          const { name } = brandData

          trackSearchSuggestionClicked({
            query,
            suggestion: {
              type: 'brand',
              name,
            },
            userShowOnlyTraceableStock: user?.showOnlyTraceableStock ?? false,
          })

          push({
            pathname: '/products',
            query: {
              brand_name: name,
            },
          })

          requestAnimationFrame(() => {
            setQuery('')
            inputRef.current?.blur()
          })

          return
        }
        case 'category': {
          const categoryData = categories[optionIndex]
          if (!categoryData) return
          const { name, urlPath } = categoryData

          trackSearchSuggestionClicked({
            query,
            suggestion: {
              type: 'category',
              name,
            },
            userShowOnlyTraceableStock: user?.showOnlyTraceableStock ?? false,
          })

          push(`/categories/${urlPath}`)

          requestAnimationFrame(() => {
            setQuery('')
            inputRef.current?.blur()
          })

          return
        }
        default:
          return
      }
    },
    (error) =>
      logWarning('ProductSearch form client-side validation error', error),
  )

  useEffect(() => {
    if (debouncedQuery) {
      trackSuggestionsSearched({
        searchTerm: debouncedQuery,
      })
    }
  }, [debouncedQuery, trackSuggestionsSearched])

  return (
    <FormProvider {...form}>
      <form
        className={className}
        onSubmit={handleSubmit}
        role="search"
        autoComplete="off"
      >
        <FormFieldController
          control={form.control}
          name="selectedSearchSuggestion"
          render={({ field }) => (
            <Combobox
              value={field.value}
              onChange={(value) => {
                field.onChange(value)
                // We submit here when a user selects an item so they don't have to press enter twice
                handleSubmit()
              }}
              immediate
              fixedWidth
              backdrop
            >
              {({ activeOption }) => (
                <div className="relative">
                  <ComboboxInput
                    ref={inputRef}
                    aria-label="Search catalog"
                    onChange={(event) => {
                      field.onChange('empty')
                      setQuery(event.target.value)
                    }}
                    value={query}
                    displayValue={() => query}
                    className="relative z-30 pr-12"
                    placeholder="Search brands, products, GTINs"
                    onKeyDown={(event) => {
                      if (
                        event.key === 'Enter' && // execute search with the current query if user presses enter while no option is active since it won't be handled on the submit handler to prevent search on blur
                        (!activeOption || activeOption === 'empty')
                      ) {
                        querySearch()
                      }
                    }}
                  />
                  <div className="absolute inset-y-0 right-2 flex items-center">
                    {isFetching ? (
                      <Loader className="[&>svg]:fill-primary-700 mr-1 h-5 w-5" />
                    ) : query ? (
                      <>
                        {query.length > 0 ? (
                          <button
                            onClick={() => {
                              setQuery('')
                              inputRef.current?.focus()
                            }}
                            type="button"
                          >
                            <XMarkIcon className="h-6 w-6 text-gray-400" />
                          </button>
                        ) : null}
                      </>
                    ) : (
                      <MagnifyingGlassIcon className="h-6 w-6 text-gray-400" />
                    )}
                  </div>
                  <ComboboxOptions className="py-2">
                    {/* don't conditional render this option. It's necessary to prevent search on blur when results are available */}
                    <ComboboxOption
                      value="empty"
                      className={cn(
                        'ui-active:bg-white ui-active:text-gray-900 py-10 text-center text-sm text-gray-900',
                        {
                          block: !query,
                          hidden: query,
                        },
                      )}
                    >
                      Type something to search
                    </ComboboxOption>
                    <div className={query ? 'block' : 'hidden'}>
                      <div className="px-2">
                        <ComboboxOption
                          value="query"
                          className={comboboxOptionStyles}
                        >
                          <div>
                            <MagnifyingGlassIcon className="float-left mr-2 inline h-6 w-6 text-gray-400" />
                            <span className="clearfix leading-6">
                              Search for&nbsp;
                              <em
                                className="text-sm font-bold not-italic"
                                translate="no"
                              >
                                &quot;{query}&quot;
                              </em>
                            </span>
                          </div>
                        </ComboboxOption>
                      </div>
                      {products.length > 0 ? (
                        <OptionGroup title="Products">
                          {products.map(
                            (product: VariantSuggestion, index: number) => (
                              <ComboboxOption
                                key={product.gtin}
                                value={`product-${index}`}
                                className={comboboxOptionStyles}
                              >
                                <ProductSuggestionResult {...product} />
                              </ComboboxOption>
                            ),
                          )}
                        </OptionGroup>
                      ) : null}
                      {brands.length > 0 ? (
                        <OptionGroup title="Brands">
                          {brands.map(
                            (brand: BrandSuggestion, index: number) => (
                              <ComboboxOption
                                key={brand.name}
                                value={`brand-${index}`}
                                className={comboboxOptionStyles}
                              >
                                <TextSuggestionResult
                                  textHighlighted={brand.nameHighlighted}
                                />
                              </ComboboxOption>
                            ),
                          )}
                        </OptionGroup>
                      ) : null}
                      {categories.length > 0 ? (
                        <OptionGroup title="Categories">
                          {categories.map(
                            (category: CategorySuggestion, index: number) => (
                              <ComboboxOption
                                key={category.name}
                                value={`category-${index}`}
                                className={comboboxOptionStyles}
                              >
                                <TextSuggestionResult
                                  textHighlighted={category.nameHighlighted}
                                />
                              </ComboboxOption>
                            ),
                          )}
                        </OptionGroup>
                      ) : null}
                      {products.length === 0 &&
                      brands.length === 0 &&
                      categories.length === 0 &&
                      isFetched ? (
                        <div className="flex flex-col items-center text-center">
                          <p className={comboboxOptionStyles}>
                            No results found.
                          </p>
                          <div className={comboboxOptionStyles}>
                            <div className="flex flex-row items-center gap-2">
                              <SparklesIcon className="text-primary-700 h-4 w-4" />
                              <NextAnchor
                                className="text-primary-700 whitespace-nowrap text-sm underline"
                                href="/products/request/"
                              >
                                Request a product or brand{' '}
                              </NextAnchor>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </ComboboxOptions>
                </div>
              )}
            </Combobox>
          )}
        />
      </form>
    </FormProvider>
  )
}

export const SearchBox = () => {
  return (
    <>
      <MobileSearch className="block md:hidden" />
      <DesktopSearch className="hidden md:block" />
    </>
  )
}
