import { forwardRef, InputHTMLAttributes } from 'react'

import { cn } from '../utils/cn'

export type InputProps = InputHTMLAttributes<HTMLInputElement>

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    return (
      <input
        className={cn(
          'focus:ring-primary-700 aria-invalid:border-error-700 aria-invalid:focus:border-error-700 aria-invalid:focus:ring-error-700 h-10 w-full min-w-0 rounded border border-gray-300 outline-none placeholder:text-base placeholder:text-gray-500 read-only:border-gray-200 read-only:bg-gray-50 read-only:text-gray-400 read-only:placeholder-gray-400 focus:border-gray-300 focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:text-gray-400 disabled:placeholder-gray-400',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

export { Input }
