import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { cn } from '../utils/cn'

/**
 * Checkbox based on ShadCN
 *
 * @see https://ui.shadcn.com/docs/components/checkbox for usage
 */
const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'h-4.5 w-4.5 focus-visible:ring-primary-700 data-[state=checked]:border-primary-700 data-[state=indeterminate]:border-primary-700 data-[state=checked]:bg-primary-700 data-[state=indeterminate]:bg-primary-700 aria-invalid:border-error-700 aria-invalid:bg-error-100 aria-invalid:data-[state=checked]:text-error-700 aria-invalid:data-[state=indeterminate]:text-error-700 inline-grid flex-none place-items-center rounded border-2 border-gray-300 bg-white text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-white disabled:data-[state=checked]:bg-gray-200 disabled:data-[state=indeterminate]:bg-gray-200',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn(
        // px translate is here for better optical centering of the icon
        'group flex -translate-x-px -translate-y-px items-center justify-center text-current',
      )}
    >
      <CheckIcon className="hidden h-4 w-4 group-data-[state=checked]:block" />
      <MinusIcon className="hidden h-4 w-4 group-data-[state=indeterminate]:block" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
