import { Country } from '@qogita/canary-types'
import { matchSorter } from 'match-sorter'
import {
  ComponentPropsWithoutRef,
  createContext,
  ElementRef,
  forwardRef,
  useContext,
  useState,
} from 'react'

import {
  Combobox,
  ComboboxEmptyValue,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../Combobox'

type CallingCodeComboboxContext = {
  callingCodeSearch: string
  setCallingCodeSearch: (search: string) => void
}

const CallingCodeComboboxContext = createContext<
  CallingCodeComboboxContext | undefined
>(undefined)
function useCallingCodeCombobox() {
  const context = useContext(CallingCodeComboboxContext)
  if (context === undefined) {
    throw new Error(
      'CallingCodeCombobox compound components must be used within a CallingCodeCombobox',
    )
  }
  return context
}

const CallingCodeCombobox = forwardRef<
  ElementRef<typeof Combobox>,
  ComponentPropsWithoutRef<typeof Combobox>
>(function CallingCodeCombobox({ children, ...props }, ref) {
  const [callingCodeSearch, setCallingCodeSearch] = useState('')

  return (
    <CallingCodeComboboxContext.Provider
      value={{ callingCodeSearch, setCallingCodeSearch }}
    >
      <Combobox {...props} ref={ref}>
        {children}
      </Combobox>
    </CallingCodeComboboxContext.Provider>
  )
})

const CallingCodeComboboxInput = forwardRef<
  ElementRef<typeof ComboboxInput>,
  Omit<ComponentPropsWithoutRef<typeof ComboboxInput>, 'value'>
>(function CallingCodeComboboxInput({ onChange, ...props }, ref) {
  const { setCallingCodeSearch } = useCallingCodeCombobox()

  return (
    <ComboboxInput
      {...props}
      ref={ref}
      onChange={(event) => {
        setCallingCodeSearch(event.target.value)
        onChange?.(event)
      }}
    />
  )
})

type CountryWithCallingCode = {
  /** Unique key - some countries have the same calling code */
  countryCode: Country
  name: string
  callingCode: string
}

function CallingCodeComboboxOptions({
  countries,
}: {
  /** List of all possible countries, the combobox will automatically filter them */
  countries: CountryWithCallingCode[]
}) {
  const { callingCodeSearch } = useCallingCodeCombobox()
  const filteredCountries = matchSorter(countries, callingCodeSearch, {
    keys: ['callingCode', 'name'],
    baseSort: (a, b) => a.item.name.localeCompare(b.item.name),
  })
  return (
    <ComboboxOptions>
      {filteredCountries.map((country) => (
        <ComboboxOption
          key={`${country.countryCode}-${country.callingCode}`}
          value={country.callingCode}
          className="flex gap-2"
        >
          <span className="max-w-[13rem] truncate">{country.name}</span>
          <span className="ui-active:text-white whitespace-nowrap text-gray-400">
            {country.callingCode}
          </span>
        </ComboboxOption>
      ))}
      {filteredCountries.length === 0 ? (
        <ComboboxEmptyValue>Not found</ComboboxEmptyValue>
      ) : null}
    </ComboboxOptions>
  )
}

export type { CountryWithCallingCode }
export {
  CallingCodeCombobox,
  CallingCodeComboboxInput,
  CallingCodeComboboxOptions,
}
