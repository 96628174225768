import { Combobox as HeadlessCombobox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { forwardRef } from 'react'

// anonymous function components are used in this fine instead of named functions
// so that we can more accurately use the headless ui types and
// transparently pass them down to consumers.
type LabelType = typeof HeadlessCombobox.Label
type LabelProps = Parameters<typeof HeadlessCombobox.Label>[0]
const Label: LabelType = ({ className, ...props }: LabelProps) => {
  return (
    <HeadlessCombobox.Label
      {...props}
      className={clsx('mb-1 block text-xs', className)}
    />
  )
}
Label.displayName = 'Label'

type InputType = typeof HeadlessCombobox.Input
type InputProps = Parameters<typeof HeadlessCombobox.Input>[0]

const Input: InputType = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }: InputProps, ref) => {
    return (
      <HeadlessCombobox.Input
        {...props}
        ref={ref}
        className={clsx(
          'focus:border-primary-500 focus:ring-primary-700 h-10 w-full rounded border border-gray-300 bg-white py-2 pl-3 pr-10 focus:outline-none focus:ring-1',
          className,
        )}
      />
    )
  },
) as InputType
Input.displayName = 'Input'

type ButtonType = typeof HeadlessCombobox.Button
type ButtonProps = Parameters<typeof HeadlessCombobox.Button>[0]
const Button: ButtonType = ({ className, ...props }: ButtonProps) => {
  return (
    <HeadlessCombobox.Button
      {...props}
      className={clsx(
        'absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none',
        className,
      )}
    >
      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </HeadlessCombobox.Button>
  )
}
Button.displayName = 'Button'

type OptionsType = typeof HeadlessCombobox.Options
type OptionsProps = Parameters<typeof HeadlessCombobox.Options>[0]
const Options: OptionsType = ({ className, ...props }: OptionsProps) => {
  return (
    <HeadlessCombobox.Options
      {...props}
      className={clsx(
        'absolute z-10 mt-1 max-h-60 min-w-full overflow-auto rounded bg-white text-base shadow-sm ring-1 ring-gray-300 focus:outline-none',
        className,
      )}
    />
  )
}
Options.displayName = 'Options'

type OptionType = typeof HeadlessCombobox.Option
type OptionProps = Parameters<typeof HeadlessCombobox.Option>[0]
const Option: OptionType = ({
  children,
  value,
  className,
  ...props
}: OptionProps) => {
  return (
    <HeadlessCombobox.Option
      {...props}
      as="li"
      className={({ active }) =>
        clsx(
          'relative cursor-default select-none py-2 pl-3 pr-9',
          { 'bg-primary-600 text-white': active },
          className,
        )
      }
      value={value}
    >
      {children}
    </HeadlessCombobox.Option>
  )
}
Option.displayName = 'Option'

type DeprecatedComboboxType = typeof HeadlessCombobox
/**
 * @deprecated
 */
export const DeprecatedCombobox: DeprecatedComboboxType = Object.assign(
  ({ ...props }) => {
    return <HeadlessCombobox {...props} />
  },
  {
    displayName: 'DeprecatedCombobox',
    Input,
    Button,
    Label,
    Options,
    Option,
  },
)
