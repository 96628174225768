import { ReactNode } from 'react'

/**
 * Stacks the children on top of each other so they occupy the same space.
 *
 * Uses grid rather than absolute positioning so each child will still take up space in the layout.
 *
 * Renders a `<span>` by default, but can be changed to a `<div>` with the `as` prop.
 */
export function LayeredStack({
  children,
  as = 'span',
}: {
  children: ReactNode
  as?: 'span' | 'div'
}) {
  const Element = as
  return (
    <Element className='grid items-center justify-items-center [grid-template-areas:"stack"] *:[grid-area:stack]'>
      {children}
    </Element>
  )
}
