import { logError } from '@qogita/logging'
import { ImageLoader } from 'next/image'

import { slugify } from './slugify'

export function getUrlPathname(url?: string) {
  // We shouldn't ever be at a point where url is undefined.
  // If our variants don't have images then canary will return an array with a
  // single element in it that points to our fallback image.
  if (!url) {
    logError('url was undefined', {
      functionArgs: { url },
    })
    return ''
  }

  try {
    const pathname = new URL(url).pathname
    return pathname
  } catch (error) {
    logError(error, {
      functionArgs: { url },
    })
    return ''
  }
}

export const vercelImageLoader: ImageLoader = ({
  src,
  width,
  quality = 75,
}) => {
  return `${src}?w=${width}&q=${quality}`
}

/**
 * Function to construct slugified array from nested category string
 * @param categoryString string representation of nested category, e.g. Health & Beauty > Personal Care > Cosmetics > Perfume & Cologne
 * @returns slugified array of category strings, e.g. ['health-beauty', 'personal-care', 'cosmetics']
 */
export const getCategorySlugArray = (categoryString: string) => {
  const nestedCategoryArray = categoryString.split(' > ')
  const slugArray = nestedCategoryArray.map((category) => slugify(category))

  return slugArray
}
