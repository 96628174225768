import { cva, VariantProps } from 'class-variance-authority'
import {
  ButtonHTMLAttributes,
  createContext,
  forwardRef,
  ReactNode,
  useContext,
} from 'react'

import { LoadingIcon } from '../Icons'
import { LayeredStack } from '../LayeredStack'
import { cn } from '../utils'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonVariantProps & { isLoading?: boolean }

export const buttonStyles = cva(
  cn(
    'inline-flex items-center justify-center gap-2 rounded border border-transparent',
    // Focus styles
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1',
    // Active styles
    'active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)] disabled:active:shadow-none',
    // Disabled styles
    'disabled:cursor-not-allowed disabled:text-gray-400 aria-disabled:cursor-not-allowed aria-disabled:text-gray-400',
  ),
  {
    variants: {
      color: {
        primary: '',
        primaryInverse: '',
        danger: '',
        custom: '',
      },
      appearance: {
        contained: 'disabled:bg-gray-200 aria-disabled:bg-gray-200',
        outlined: 'disabled:border-gray-300 aria-disabled:border-gray-300',
        text: 'active:shadow-none',
      },
      size: {
        small: 'text-sm font-medium tracking-wider',
        medium: 'text-base font-medium tracking-wider',
        large: 'text-lg font-medium tracking-wider',
        inline: 'underline [font-size:inherit] [font-weight:inherit]',
      },
    },
    compoundVariants: [
      {
        size: 'small',
        appearance: ['contained', 'outlined'],
        className: 'px-2 py-1.5',
      },
      {
        size: 'medium',
        appearance: ['contained', 'outlined'],
        className: 'px-4 py-2',
      },
      {
        size: 'large',
        appearance: ['contained', 'outlined'],
        className: 'px-4 py-3',
      },
      {
        color: 'primary',
        appearance: 'contained',
        size: ['small', 'medium', 'large'],
        className:
          'bg-primary-700 hover:bg-primary-800 focus-visible:bg-primary-800 active:bg-primary-900 outline-primary-700 text-white',
      },
      {
        color: 'primary',
        appearance: 'outlined',
        size: ['small', 'medium', 'large'],
        className:
          'text-primary-900 hover:bg-primary-50/50 focus-visible:bg-primary-50/50 border-primary-900 outline-primary-700',
      },
      {
        color: 'primary',
        appearance: 'text',
        size: ['small', 'medium', 'large'],
        className:
          'text-primary-700 hover:text-primary-800 focus-visible:text-primary-800 active:text-primary-900 outline-primary-700',
      },
      {
        color: 'primary',
        size: 'inline',
        className:
          'text-primary-700 hover:text-primary-800 focus-visible:text-primary-800 outline-primary-700',
      },
      {
        color: 'primaryInverse',
        appearance: 'contained',
        size: ['small', 'medium', 'large'],
        className:
          'text-primary-900 hover:bg-primary-50 focus-visible:bg-primary-50 bg-white',
      },
      {
        color: 'danger',
        appearance: 'contained',
        size: ['small', 'medium', 'large'],
        className:
          'bg-error-700 hover:bg-error-800 focus-visible:bg-error-800 active:bg-error-900 text-white',
      },
      {
        color: 'danger',
        appearance: 'outlined',
        size: ['small', 'medium', 'large'],
        className:
          'text-error-900 border-error-900 hover:bg-error-50/50 focus-visible:bg-error-50/50 border',
      },
      {
        color: 'danger',
        appearance: 'text',
        size: ['small', 'medium', 'large'],
        className:
          'text-error-700 hover:text-error-800 focus-visible:text-error-800 active:text-error-900',
      },
    ],
    defaultVariants: {
      color: 'primary',
      appearance: 'contained',
      size: 'medium',
    },
  },
)
type ButtonVariantProps = VariantProps<typeof buttonStyles>

const ButtonContext = createContext<{ isLoading?: boolean } | null>(null)
function useButtonContext() {
  const context = useContext(ButtonContext)
  if (context === null) {
    throw new Error('useButtonContext must be used within a <Button/>')
  }
  return context
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { color, appearance, size, className, type = 'button', isLoading, ...props },
  ref,
) {
  return (
    <ButtonContext.Provider value={{ isLoading }}>
      <button
        className={cn(buttonStyles({ color, appearance, size }), className)}
        type={type}
        ref={ref}
        aria-busy={isLoading ? true : undefined}
        {...props}
      />
    </ButtonContext.Provider>
  )
})
Button.displayName = 'Button'

/**
 * Wrapper for an icon inside a button
 *
 * @example
 * <Button>
 *   <Button.Icon>
 *     <CartIcon />
 *   </Button.Icon>
 *   Add to cart
 * </Button>
 */
function Icon({ children }: { children: ReactNode }) {
  return <span className="size-4.5 block shrink-0">{children}</span>
}
Icon.displayName = 'Button.Icon'

/**
 * Renders a loading spinner whenever the Button's `isLoading` prop is true
 * If any children are provided, they will be hidden while the spinner is visible
 *
 * @example
 * <Button isLoading>
 *   <Button.Loader>
 *     <Button.Icon>
 *       <DiscIcon />
 *     </Button.Icon>
 *   </Button.Loader>
 *   Save
 * </Button>
 *  */
function Loader({ children }: { children?: ReactNode }) {
  const { isLoading } = useButtonContext()
  return (
    <LayeredStack>
      <span
        className={cn(
          'transition-opacity',
          isLoading ? 'opacity-0' : 'opacity-100',
        )}
      >
        {children}
      </span>
      <LoadingIcon
        aria-hidden
        className={cn(
          'size-4.5 transition-opacity',
          isLoading ? 'opacity-100' : 'opacity-0',
        )}
      />
    </LayeredStack>
  )
}
Loader.displayName = 'Button.Loader'

// We have to do this extra faff to use subcomponents on a forwardRef component
// With React 19, we won't need forwardRef, and this can be simpler
const ButtonRoot = Object.assign(Button, { Icon, Loader })
export { ButtonRoot as Button }
