import { OrderRetrieve } from '@qogita/canary-types'

import { getCurrencyFormatter } from '#lib/currency'

/**
 * Format a currency amount in accounting format with currency symbol
 */
export function CurrencyAmount({
  amount,
  currency,
}: {
  amount: number | string
  currency: OrderRetrieve['currency']
}) {
  const currencyFormatter = getCurrencyFormatter(currency)
  return <span className="whitespace-nowrap">{currencyFormatter(amount)}</span>
}
