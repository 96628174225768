type GetMinAndMaxFromArray = (array: number[]) => [number, number]

/**
 * @param array Array of numbers
 * @returns [min, max] values from the array
 */
export const getMinAndMaxFromArray: GetMinAndMaxFromArray = (array) => {
  return [Math.min(...array), Math.max(...array)]
}

/**
 * Converts a value that may be an array or a single value into a guaranteed array
 * @param value single value or array of values
 * @returns array of values
 */
export const toArray = <Value>(value: Value | Value[] = []): Value[] => {
  return Array.isArray(value) ? value : [value]
}

/**
 * Type safe alternative to Arry.includes when working with arrays
 * of literal values
 * https://fettblog.eu/typescript-array-includes/#option-2%3A-a-helper-with-type-assertions
 * @param collection array of literals
 * @param element element to check if in array
 * @returns boolean
 */
export function includes<T extends U, U>(
  collection: ReadonlyArray<T>,
  element: U,
): element is T {
  return collection.includes(element as T)
}

/**
 * Returns a continuous array of numbers from start to end
 * @param start starting number
 * @param end ending number
 * @returns number[]
 */
export const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, index) => index + start)
