import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import {
  ComponentPropsWithoutRef,
  createContext,
  ReactNode,
  useContext,
  useState,
} from 'react'

import { DeprecatedButton, DeprecatedButtonProps } from '../Button'

type ToastContext = {
  variant: 'neutral' | 'info' | 'caution' | 'success' | 'error'
}

const ToastContext = createContext<ToastContext | null>(null)

function useToast() {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('[Qogita UI] Toast components must be used within Toast')
  }
  return context
}

type ToastProps = ToastContext & {
  /**
   * Use "assertive" if the toast requires immediate attention and "polite" if it can
   * be announced after the current screen reader activity is finished.
   */
  'aria-live'?: 'assertive' | 'polite'
  /**
   * Specifies that the entire contents of the toast should be presented as a whole
   * to the screen reader, ensuring that the message is read in its entirety.
   */
  'aria-atomic'?: boolean
  /**
   * Use "alert" for critical alerts, error messages, or warnings that require
   * immediate attention. Use "status" for providing status updates or
   * informative messages that don't require immediate action.
   */
  role: 'alert' | 'status'
} & Omit<ComponentPropsWithoutRef<'div'>, 'role' | 'aria-atomic' | 'aria-live'>

function ToastRoot({
  children,
  className,
  role,
  'aria-live': ariaLive = 'polite',
  'aria-atomic': ariaAtomic = false,
  variant,
  ...divProps
}: ToastProps) {
  return (
    <div
      {...divProps}
      role={role}
      aria-live={ariaLive}
      aria-atomic={ariaAtomic}
      className={clsx(
        'flex max-w-prose items-center gap-4 overflow-hidden rounded-sm border-l-[6px] pl-3 pr-4',
        {
          'border-gray-400 bg-gray-100 text-gray-900': variant === 'neutral',
          'border-info-700 bg-info-50 text-info-900': variant === 'info',
          'border-caution-700 bg-caution-50 text-caution-900':
            variant === 'caution',
          'border-success-700 bg-success-50 text-success-900':
            variant === 'success',
          'border-error-700 bg-error-50 text-error-900': variant === 'error',
        },
        className,
      )}
    >
      <ToastContext.Provider value={{ variant }}>
        {children}
      </ToastContext.Provider>
    </div>
  )
}

const iconsMap = {
  neutral: ExclamationCircleIcon,
  info: InformationCircleIcon,
  caution: ExclamationCircleIcon,
  success: CheckCircleIcon,
  error: ExclamationTriangleIcon,
} as const

function ToastIcon() {
  const { variant } = useToast()
  const Icon = iconsMap[variant]

  return (
    <Icon
      className={clsx('h-6 w-6 flex-none', {
        'text-gray-400': variant === 'neutral',
        'text-info-700': variant === 'info',
        'text-caution-700': variant === 'caution',
        'text-success-700': variant === 'success',
        'text-error-700': variant === 'error',
      })}
    />
  )
}

type ToastCloseButtonProps = {
  onClose: () => void
}
function ToastCloseButton({ onClose }: ToastCloseButtonProps) {
  const { variant } = useToast()
  return (
    <div className="flex gap-4 self-stretch py-2">
      <div
        className={clsx('w-[1px] self-stretch', {
          'bg-gray-400': variant === 'neutral',
          'bg-info-700': variant === 'info',
          'bg-caution-700': variant === 'caution',
          'bg-success-700': variant === 'success',
          'bg-error-700': variant === 'error',
        })}
      />
      <button onClick={onClose} aria-label="Close" type="button">
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
  )
}

const ToastButtonVariantMap = {
  neutral: 'primaryText',
  info: 'infoText',
  caution: 'cautionText',
  success: 'successText',
  error: 'errorText',
} as const

type ToastButtonProps = Omit<
  DeprecatedButtonProps,
  'variant' | 'onClick' | 'type'
> & {
  onClick: () => void
}
function ToastButton({ className, ...props }: ToastButtonProps) {
  const { variant } = useToast()

  return (
    <DeprecatedButton
      {...props}
      type="button"
      variant={ToastButtonVariantMap[variant]}
      className={clsx('text-sm', className)}
    />
  )
}

type ToastTitleProps = {
  children: ReactNode
  className?: string
}
function ToastMessage({ children, className }: ToastTitleProps) {
  return <p className={clsx('py-2 text-base', className)}>{children}</p>
}

export const Toast = Object.assign(ToastRoot, {
  Icon: ToastIcon,
  CloseButton: ToastCloseButton,
  Message: ToastMessage,
  Button: ToastButton,
})

export function TestToast() {
  const [isOpen, setIsOpen] = useState(true)
  return isOpen ? (
    <Toast variant="info" role="alert">
      <Toast.Icon />
      <Toast.Message>Message</Toast.Message>
      <Toast.Button onClick={() => undefined}>Label</Toast.Button>
      <Toast.CloseButton onClose={() => setIsOpen(false)} />
    </Toast>
  ) : null
}
