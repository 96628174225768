import * as LabelPrimitive from '@radix-ui/react-label'
import { Slot } from '@radix-ui/react-slot'
import {
  ComponentPropsWithoutRef,
  createContext,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  useContext,
  useId,
} from 'react'
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
} from 'react-hook-form'

import { Label } from '../Label'
import { cn } from '../utils/cn'

const Form = forwardRef<HTMLFormElement, HTMLAttributes<HTMLFormElement>>(
  (props, ref) => {
    return <form ref={ref} {...props} />
  },
)
Form.displayName = 'Form'

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName
}

const FormFieldContext = createContext<FormFieldContextValue | undefined>(
  undefined,
)

const FormFieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  )
}

function useFormFieldContext() {
  const context = useContext(FormFieldContext)

  if (!context) {
    throw new Error('useFormFieldContext should be used within <FormField>')
  }

  return context
}

type FormItemContextValue = {
  id: string
}

const FormItemContext = createContext<FormItemContextValue | undefined>(
  undefined,
)

function useFormItemContext() {
  const context = useContext(FormItemContext)

  if (!context) {
    throw new Error('useFormItemContext should be used within <FormItem>')
  }

  return context
}

const FormItem = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { asChild?: true }
>(({ className, asChild, ...props }, ref) => {
  const id = useId()
  const Component = asChild ? Slot : 'div'
  return (
    <FormItemContext.Provider value={{ id }}>
      <Component
        ref={ref}
        className={cn('flex flex-col gap-1', className)}
        {...props}
      />
    </FormItemContext.Provider>
  )
})
FormItem.displayName = 'FormItem'

export const useFormItem = () => {
  const fieldContext = useFormFieldContext()
  const itemContext = useFormItemContext()
  const { getFieldState, formState } = useFormContext()

  const fieldState = getFieldState(fieldContext.name, formState)

  const { id } = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  }
}

const FormLabel = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    /** Indicates a non-required field */
    optional?: boolean
  }
>(({ children, optional, ...props }, ref) => {
  const { formItemId } = useFormItem()

  return (
    <Label ref={ref} htmlFor={formItemId} {...props}>
      {children}
      {optional ? (
        <span className="ml-auto text-gray-500">Optional</span>
      ) : null}
    </Label>
  )
})
FormLabel.displayName = 'FormLabel'

const FormControl = forwardRef<
  ElementRef<typeof Slot>,
  ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormItem()

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        error ? `${formDescriptionId} ${formMessageId}` : `${formDescriptionId}`
      }
      aria-invalid={Boolean(error)}
      {...props}
    />
  )
})
FormControl.displayName = 'FormControl'

const FormDescription = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement> & { asChild?: true }
>(({ className, asChild, ...props }, ref) => {
  const { formDescriptionId } = useFormItem()

  const Component = asChild ? Slot : 'p'

  return (
    <Component
      ref={ref}
      id={formDescriptionId}
      className={cn('text-gray-700', className)}
      {...props}
    />
  )
})
FormDescription.displayName = 'FormDescription'

const FormMessage = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormItem()
  const body = children ?? error?.message

  if (!body) {
    return null
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn('text-error-700 text-sm', className)}
      {...props}
    >
      {body}
    </p>
  )
})
FormMessage.displayName = 'FormMessage'

export {
  Form,
  FormControl,
  FormDescription,
  FormFieldController,
  FormItem,
  FormLabel,
  FormMessage,
  FormProvider,
}
