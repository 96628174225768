import {
  CanaryClient,
  GetCartLinesSearchParams,
  useCanaryClient,
} from '@qogita/canary-client'
import {
  CheckoutCompleteRequest,
  PatchedCheckoutRequest,
} from '@qogita/canary-types'
import {
  matchQuery,
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { useTrackEvent } from '#lib/report/tracking'

import { getCartQueries } from './cart-queries'
import { getOrderQueries } from './order-queries'
import { getUserQueries } from './user-queries'
import { replaceUndefinedValuesWithNull } from './utils'

export function getCheckoutQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['checkouts'] as const,
    allDetails: () => [...queries.all(), 'detail'] as const,
    detail: (qid: string) =>
      queryOptions({
        queryKey: [...queries.allDetails(), qid] as const,
        queryFn: () => canaryClient.getCheckout(qid),
      }),
    allLines: (qid: string) =>
      [...queries.detail(qid).queryKey, 'lines'] as const,
    linesList: (qid: string, params: GetCartLinesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLines(qid),
          'list',
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getCheckoutLines(qid, params),
      }),
  }
  return queries
}

export function useCompleteCheckoutMutation(qid: string) {
  const canaryClient = useCanaryClient()
  const checkoutQueries = getCheckoutQueries(canaryClient)
  const cartQueries = getCartQueries(canaryClient)
  const orderQueries = getOrderQueries(canaryClient)
  const userQueries = getUserQueries(canaryClient)
  const queryClient = useQueryClient()
  const { trackCheckoutCompleted, trackFirstCheckoutCompleted } =
    useTrackEvent()

  return useMutation({
    mutationKey: [...checkoutQueries.detail(qid).queryKey, 'complete'] as const,
    mutationFn: (data: CheckoutCompleteRequest) =>
      canaryClient.completeCheckout(qid, data),
    onSuccess: async (order) => {
      if (order.isFirstCheckout) {
        const user = queryClient.getQueryData(userQueries.detail().queryKey)
        trackFirstCheckoutCompleted({ order, email: user?.email })
      }
      trackCheckoutCompleted({ order })

      queryClient.setQueryData(orderQueries.detail(order.qid).queryKey, order)

      // We want to exclude the current checkout and current cart from the query invalidation
      // The process of completing the checkout will delete them on the backend, so if we try and refetch them
      // before we've navigated away from the checkout page, we'll get 404s
      const currentCheckout = queryClient.getQueryData(
        checkoutQueries.detail(qid).queryKey,
      )
      const currentCart = currentCheckout
        ? queryClient.getQueryData(
            cartQueries.detail(currentCheckout.cartQid).queryKey,
          )
        : null

      await queryClient.invalidateQueries({
        predicate: (query) => {
          // We can use react query's fuzzy matching to not invalidate queries related to
          // the current checkout or the current cart
          // see examples: https://tkdodo.eu/blog/automatic-query-invalidation-after-mutations#use-the-meta-option
          const matchesCurrentCheckout = matchQuery(
            {
              queryKey: checkoutQueries.detail(qid).queryKey,
            },
            query,
          )

          const matchesCurrentCart =
            currentCart &&
            matchQuery(
              { queryKey: cartQueries.detail(currentCart.qid).queryKey },
              query,
            )

          if (matchesCurrentCheckout || matchesCurrentCart) {
            return false
          }

          return true
        },
      })

      // If we have a redirectUrl, this is to an external payment provider, so we open it, replacing the current tab
      // We do this here in the useMutation onSuccess rather than .mutate() because
      // we _must_ navigate to the provider, even if the component calling the mutation has unmounted
      if (order.redirectUrl) {
        window.open(order.redirectUrl, '_self')
      }
    },
  })
}

export function useUpdateCheckoutMutation(qid: string) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const checkoutQueries = getCheckoutQueries(canaryClient)

  return useMutation({
    mutationKey: [...checkoutQueries.detail(qid).queryKey, 'update'] as const,
    mutationFn: (data: PatchedCheckoutRequest) =>
      canaryClient.updateCheckout(qid, data),
    onSuccess: async (checkout) => {
      queryClient.setQueryData(checkoutQueries.detail(qid).queryKey, checkout)
      await queryClient.invalidateQueries()
    },
  })
}
