import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { Slot } from '@radix-ui/react-slot'
import {
  ComponentPropsWithoutRef,
  createContext,
  ElementRef,
  forwardRef,
  useContext,
  useId,
} from 'react'

import { Label } from '../Label'
import { cn } from '../utils/cn'

type RadioGroupContext = {
  legendId: string
}

const RadioGroupContext = createContext<RadioGroupContext | undefined>(
  undefined,
)

function useRadioGroupContext() {
  const context = useContext(RadioGroupContext)
  if (context === undefined) {
    throw new Error(
      'RadioGroup compound components must be used within a RadioGroup',
    )
  }
  return context
}

const RadioGroup = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, asChild, children, ...props }, ref) => {
  const legendId = useId()
  const Component = asChild ? Slot : 'fieldset'

  return (
    <RadioGroupContext.Provider value={{ legendId }}>
      <RadioGroupPrimitive.Root
        className={cn('grid gap-2', className)}
        {...props}
        aria-labelledby={legendId}
        ref={ref}
        asChild
      >
        <Component>{children}</Component>
      </RadioGroupPrimitive.Root>
    </RadioGroupContext.Provider>
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupLegend = forwardRef<
  ElementRef<typeof Label>,
  ComponentPropsWithoutRef<typeof Label>
>(function RadioGroupLegend({ asChild, children, ...props }, ref) {
  const Component = asChild ? Slot : 'legend'
  const { legendId } = useRadioGroupContext()

  return (
    <Label {...props} id={legendId} ref={ref} asChild>
      <Component>{children}</Component>
    </Label>
  )
})

const RadioGroupItem = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'outline-primary-700 text-primary-700 focus-visible:ring-primary-700 data-[state=checked]:border-primary-700 relative aspect-square size-4 h-4 w-4 overflow-hidden rounded-full border-[2px] border-gray-300 bg-white ring-gray-300 ring-offset-1 focus-visible:ring-2 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-[5px]',
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="absolute inset-0 flex items-center justify-center">
        {children}
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem, RadioGroupLegend }
