import clsx from 'clsx'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { Footer } from '#components/Footer'
import { Masthead } from '#components/Masthead'
import { environment } from '#lib/environment.mjs'
import { DEFAULT_OPEN_GRAPH_IMAGE } from '#lib/info'
import { PageStoryblok } from '#types/storyblok-component-types'

export type LayoutProps = {
  padded?: boolean
  children: React.ReactNode
  title: string
  description: string
  pageBlok: PageStoryblok | null
  openGraph?: {
    description?: string
    image?: {
      url: string
      width: string
      height: string
    }
  }
}

export const Layout = ({
  children,
  title,
  description,
  openGraph: openGraphOverrides,
  padded: isPadded = true,
  pageBlok,
}: LayoutProps): JSX.Element => {
  const router = useRouter()

  const openGraphMeta = {
    siteName: 'Qogita',
    image: DEFAULT_OPEN_GRAPH_IMAGE,
    twitterCard: 'summary',
    title,
    description,
    url: `${environment.NEXT_PUBLIC_BASE_URL}${router.asPath}`,
    ...openGraphOverrides,
  }

  return (
    <div className="flex min-h-screen flex-col">
      <Head>
        <title key="title">{title}</title>
        <meta name="description" content={description} key="description" />
        <meta property="og:site_name" content={openGraphMeta.siteName} />
        <meta
          property="og:title"
          content={openGraphMeta.title}
          key="og:title"
        />
        <meta
          property="og:description"
          content={openGraphMeta.description}
          key="og:description"
        />
        <meta property="og:url" content={openGraphMeta.url} />
        <meta
          property="og:image"
          content={openGraphMeta.image.url}
          key="og:image"
        />
        <meta
          property="og:image:width"
          content={openGraphMeta.image.width}
          key="og:image:width"
        />
        <meta
          property="og:image:height"
          content={openGraphMeta.image.height}
          key="og:image:height"
        />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content={openGraphMeta.twitterCard} />
      </Head>
      <Masthead />
      <div
        className={clsx('flex-grow', {
          'py-2': isPadded,
        })}
      >
        {children}
      </div>
      <Footer pageBlok={pageBlok} />
    </div>
  )
}
