import { createIcon } from '#components/Icon'

export const MasterCardIcon = createIcon(
  <>
    <g clipPath="url(#master-card-clip-path)">
      <path
        d="M18.189 16.33h-.413v.231a.72.72 0 0 0-.6-.278c-.532 0-.95.418-.95.997 0 .58.418.997.95.997a.72.72 0 0 0 .6-.278v.23h.413V16.33Zm-.961 1.557c-.358 0-.577-.274-.577-.608 0-.334.22-.608.577-.608s.571.262.571.608-.23.608-.571.608ZM15.32 16.671c.18 0 .342.06.498.171l.198-.333c-.206-.163-.397-.227-.683-.227-.611 0-1.012.421-1.012.997 0 .576.4.997 1.012.997.286 0 .477-.064.683-.226l-.198-.334a.834.834 0 0 1-.498.17c-.33-.003-.572-.241-.572-.607s.243-.604.572-.608ZM13.903 16.283a.56.56 0 0 0-.5.278v-.23h-.409v1.898h.413v-1.065c0-.314.135-.488.405-.488a.67.67 0 0 1 .258.047l.127-.389a.878.878 0 0 0-.294-.052ZM11.668 16.282c-.557 0-.946.405-.946.997s.405.997.973.997c.286 0 .548-.071.779-.266l-.203-.306a.905.905 0 0 1-.552.199c-.266 0-.508-.123-.568-.465h1.41c.004-.052.008-.103.008-.159-.004-.592-.37-.997-.902-.997Zm-.513.83c.044-.273.21-.46.504-.46.266 0 .437.167.48.46h-.984ZM19.642 16.282a.56.56 0 0 0-.5.278v-.23h-.41v1.899h.414v-1.065c0-.314.135-.488.405-.488a.667.667 0 0 1 .258.047l.127-.389a.877.877 0 0 0-.294-.052ZM22.777 18.146a.047.047 0 0 0 .014-.036.05.05 0 0 0-.018-.04.08.08 0 0 0-.052-.016h-.08v.176h.037v-.066h.018l.053.066h.044l-.058-.067a.07.07 0 0 0 .042-.017Zm-.1-.013v-.046h.044c.008 0 .016.001.023.006a.02.02 0 0 1 .009.017.02.02 0 0 1-.009.018.046.046 0 0 1-.023.005h-.043ZM21.609 16.561a.72.72 0 0 0-.6-.278c-.532 0-.95.418-.95.997 0 .58.418.997.95.997a.72.72 0 0 0 .6-.278v.23h.413v-2.661h-.413v.993Zm-.548 1.327c-.358 0-.576-.275-.576-.608 0-.334.218-.608.576-.608.357 0 .571.262.571.608 0 .345-.23.608-.571.608Z"
        fill="#0D0508"
      />
      <path
        d="M22.887 18.071a.194.194 0 0 0-.177-.116v-.001a.193.193 0 0 0-.178.115c-.02.047-.02.1 0 .146.01.023.023.045.04.063a.198.198 0 0 0 .213.04.193.193 0 0 0 .102-.1c.02-.047.02-.1 0-.147Zm-.043.13a.147.147 0 0 1-.134.089v.001a.148.148 0 0 1-.136-.09.15.15 0 0 1 .03-.161.147.147 0 0 1 .106-.042.146.146 0 0 1 .103.042.15.15 0 0 1 .031.16ZM9.98 17.883c-.207 0-.275-.127-.275-.317v-.858h.675v-.378h-.675v-.576h-.417v.576h-.385v.377h.385v.866c0 .44.171.703.66.703a.97.97 0 0 0 .516-.147l-.12-.353a.764.764 0 0 1-.365.107ZM6.123 16.33v.231a.72.72 0 0 0-.6-.278c-.532 0-.95.418-.95.997 0 .58.418.997.95.997a.72.72 0 0 0 .6-.278v.23h.412V16.33h-.412Zm-.548 1.558c-.358 0-.576-.275-.576-.608 0-.334.218-.608.576-.608.357 0 .572.262.572.608 0 .345-.23.607-.572.607ZM3.462 16.283c-.243-.004-.493.071-.668.338a.697.697 0 0 0-.627-.338c-.203 0-.401.06-.556.282v-.235h-.413v1.898h.416v-1.052c0-.33.183-.505.465-.505.282 0 .413.179.413.5v1.057h.417v-1.052c0-.33.19-.505.465-.505.274 0 .416.179.416.5v1.057h.419v-1.19c0-.45-.286-.751-.747-.755ZM7.962 17.09l-.195-.029c-.178-.023-.321-.059-.321-.186 0-.139.134-.223.361-.223.242 0 .477.092.592.163l.179-.334h-.001c-.199-.13-.472-.199-.774-.199-.481 0-.79.231-.79.608 0 .31.23.5.654.56l.195.028c.227.032.333.091.333.199 0 .146-.15.23-.432.23a1.02 1.02 0 0 1-.632-.198l-.194.322c.227.166.513.246.822.246.548 0 .866-.258.866-.62 0-.334-.25-.508-.663-.568Z"
        fill="#0D0508"
      />
      <path
        d="m22.841 11.636-.084.191-.083-.19h-.072v.277h.051v-.209l.078.18h.054l.078-.18v.21h.05v-.278h-.072ZM22.547 11.637h-.236v.047h.092v.23h.051v-.23h.093v-.047Z"
        fill="#F79410"
      />
      <path
        d="M8.837 7.483a7.138 7.138 0 0 0 2.73 5.622h.002a7.137 7.137 0 0 0 2.73-5.622 7.137 7.137 0 0 0-2.73-5.621h-.001a7.139 7.139 0 0 0-2.73 5.621Z"
        fill="#FF5F00"
      />
      <path
        d="M7.15 14.634a7.12 7.12 0 0 0 4.418-1.53 7.138 7.138 0 0 1-2.73-5.62 7.138 7.138 0 0 1 2.73-5.622A7.15 7.15 0 0 0 0 7.484a7.15 7.15 0 0 0 7.15 7.15Z"
        fill="#EB001B"
      />
      <path
        d="M14.3 7.483a7.137 7.137 0 0 1-2.732 5.622 7.15 7.15 0 0 0 11.569-5.622 7.15 7.15 0 0 0-11.569-5.621A7.137 7.137 0 0 1 14.3 7.483Z"
        fill="#F79E1B"
      />
    </g>
    <defs>
      <clipPath id="master-card-clip-path">
        <path fill="#fff" transform="translate(0 .333)" d="M0 0h23.137v18H0z" />
      </clipPath>
    </defs>
  </>,
  'MasterCardIcon',
  '0 0 24 19',
)
