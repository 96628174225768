import { User } from '@qogita/canary-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useUpdateUser } from 'src/api/user-queries'

import { useAuthentication } from '#contexts/Authentication'
import { useConsent } from '#contexts/Consent'
import { setBrowserCookie } from '#lib/cookie'

import { ANONYMOUS_PREFERENCES_KEY } from './useLocalisationPreferences'

type LocalisationPreferences = {
  country: User['country']
  currency: User['currency']
}

export const useUpdateLocalisationPreferences = () => {
  const queryClient = useQueryClient()

  const { hasConsent } = useConsent()
  const { isAuthenticated } = useAuthentication()

  // Authenticated user update
  const updateUser = useUpdateUser()

  // Anonymous user update
  const updateAnonymousPreferences = useMutation({
    mutationFn: async (preferences: LocalisationPreferences) => {
      // if user doesn't consent to cookies we'll try writing preferences to local storage
      if (!hasConsent('functional')) {
        if (!window.localStorage) {
          // Bail out, if we can't store cookies and local storage doesn't exist, we're not going to remember the user preferences
          return
        }

        return window.localStorage.setItem(
          ANONYMOUS_PREFERENCES_KEY,
          JSON.stringify(preferences),
        )
      }

      // User does allow cookies, so we'll write one
      setBrowserCookie(ANONYMOUS_PREFERENCES_KEY, JSON.stringify(preferences), {
        maxAge: 60 * 60 * 24 * 365, // 1 year
        path: '/',
      })
    },
  })

  // We can't conditionally return hooks, so we'll set up a new mutation that will call the correct function based on isAuthenticated
  return useMutation({
    mutationFn: async (preferences: LocalisationPreferences) => {
      if (isAuthenticated) {
        return await updateUser.mutateAsync(preferences)
      }
      return await updateAnonymousPreferences.mutateAsync(preferences)
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}
