import Image from 'next/image'

import { IconStoryblok } from '#types/storyblok-component-types'

import { CmsPanelErrorBoundary } from './CmsPanelErrorBoundary'

function IconComponent({ blok }: { blok: IconStoryblok }) {
  return (
    <Image
      loader={({ src }) => src}
      src={blok.asset.filename}
      alt=""
      width={24}
      height={24}
      unoptimized
    />
  )
}

export const Icon = ({ blok }: { blok: IconStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <IconComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
