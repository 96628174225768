import type { ReactElement } from 'react'

import { DeprecatedField } from './DeprecatedField'
import { DeprecatedFieldDescription } from './DeprecatedFieldDescription'
import { DeprecatedFormLegend } from './DeprecatedFieldLabel'
import {
  DeprecatedFormFieldsetOptions,
  type FormFieldSetParam as FormFieldSetParameter,
  useDeprecatedFormField,
} from './useDeprecatedFormField'

type FormFieldsetProps = FormFieldSetParameter & {
  children: (
    options: DeprecatedFormFieldsetOptions,
  ) => ReactElement | ReactElement[]
  className?: string
}

/**
 * @deprecated
 */
export function DeprecatedFormFieldset({
  className,
  children,
  ...props
}: FormFieldsetProps) {
  const { fieldProps, labelProps, descriptionProps, fieldsetOptions } =
    useDeprecatedFormField({
      ...props,
      fieldset: true,
    })

  return (
    <DeprecatedField {...fieldProps} className={className}>
      {!('htmlFor' in labelProps) && <DeprecatedFormLegend {...labelProps} />}

      {descriptionProps && <DeprecatedFieldDescription {...descriptionProps} />}

      {fieldsetOptions && children(fieldsetOptions)}
    </DeprecatedField>
  )
}
