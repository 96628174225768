import { ReactNode } from 'react'

const TestimonialsSection = ({ children }: { children: ReactNode }) => (
  <section className="bg-marketing-brightblue-100 flex flex-col items-center py-12 md:px-32 md:py-24">
    <div className="flex max-w-5xl flex-col gap-9 px-4 md:gap-14 md:p-0">
      {children}
    </div>
  </section>
)

const TestimonialsSectionHeader = ({ children }: { children: ReactNode }) => (
  <h3 className="text-center text-4xl font-bold">{children}</h3>
)

const TestimonialsGrid = ({ children }: { children: ReactNode }) => (
  <div className="grid grid-cols-1 gap-x-6 gap-y-14 lg:grid-cols-2">
    {children}
  </div>
)

const TestimonialWrapper = ({ children }: { children: ReactNode }) => {
  return <article>{children}</article>
}

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return <p className="mb-3 text-lg font-bold font-light">{children}</p>
}

const TestimonialName = ({ children }: { children: ReactNode }) => {
  return <p className="text-base font-bold">{children}</p>
}

const TestimonialJobTitle = ({ children }: { children: ReactNode }) => {
  return <p className="text-base">{children}</p>
}

const TestimonialIcon = () => {
  return (
    <svg
      className="fill-primary-300 mb-3 md:mb-6"
      width="37"
      height="28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M15.63 6V0C7.01 0 0 8.72 0 19.44v.02c0 4.32 3.5 7.81 7.81 7.81s7.81-3.5 7.81-7.81-3.5-7.81-7.81-7.81c-.13 0-.26.01-.39.02C9.25 8.24 12.24 6 15.62 6h.01ZM36.73 6V0C28.11 0 21.1 8.72 21.1 19.44v.02c0 4.32 3.5 7.81 7.81 7.81s7.81-3.5 7.81-7.81-3.5-7.81-7.81-7.81c-.13 0-.26.01-.39.02C30.35 8.24 33.34 6 36.72 6h.01Z" />
      </g>
    </svg>
  )
}

export const Testimonial = Object.assign(TestimonialWrapper, {
  Content: TestimonialContent,
  Name: TestimonialName,
  JobTitle: TestimonialJobTitle,
  Icon: TestimonialIcon,
})

export const Testimonials = Object.assign(TestimonialsSection, {
  Header: TestimonialsSectionHeader,
  Grid: TestimonialsGrid,
})
