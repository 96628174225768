import { Anchor } from '@qogita/ui'
import { useEffect, useRef } from 'react'

export const TrustBoxMicroTrustComboScore = () => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // This work in conjuction with the script loaded in
    // _app.tsx, when it's read window.Trustpilot becomes ready
    // and can be used here to initialise the trustbox
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <div
      ref={ref}
      className="trustpilot-widget h-10 md:h-5"
      data-locale="en-US"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="61e6a507aa6867337dd09e64"
      data-style-width="100%"
      data-theme="light"
    >
      <Anchor
        href="https://www.trustpilot.com/review/www.qogita.com"
        target="_blank"
        rel="noopener"
      >
        See our reviews on Trustpilot
      </Anchor>
    </div>
  )
}
