import { initDatadog } from '@qogita/logging'
import { useEffect } from 'react'

import { useConsent } from '#contexts/Consent'
import { environment } from '#lib/environment.mjs'

const isDatadogEnabled = environment.NEXT_PUBLIC_DATADOG_RUM_ENABLED

export function DatadogInit() {
  const { consent } = useConsent()
  const isDatadogAccepted =
    consent.status !== 'loading' && consent.value.performance

  useEffect(() => {
    if (isDatadogAccepted && isDatadogEnabled) {
      initDatadog({
        trackResources: true,
      })
    }
  }, [isDatadogAccepted])

  return null
}
