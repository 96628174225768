import { PresignedPost } from '@aws-sdk/s3-presigned-post'
import ky, { HTTPError } from 'ky'

export type UploadFile = {
  destination: string
  binaryFile: File
}

export class FileUploadError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public filename: string,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'FileUploadError'
  }
}

export const uploadFileToStorage = async (file: UploadFile) => {
  const { destination, binaryFile } = file
  const filename = binaryFile.name
  const { url, fields } = await ky
    .get<PresignedPost>(
      `/api/presignUrl/?file=${filename}&destination=${destination}`,
    )
    .json()

  const formData = new FormData()

  Object.entries({ ...fields, file: binaryFile }).forEach(([key, value]) => {
    formData.append(key, value as string | Blob)
  })

  await ky
    .extend({
      hooks: {
        beforeError: [
          (error) => {
            return new FileUploadError(error, filename)
          },
        ],
      },
    })
    .post(url, {
      timeout: false, // let AWS control the timeout
      body: formData,
      headers: {
        'Content-Length': binaryFile.size.toString(),
      },
    })

  return destination
}
