import { CanaryClient } from '@qogita/canary-client'
import { queryOptions } from '@tanstack/react-query'

import { ONE_HOUR_MS } from '#lib/time'

export function getInfoQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () =>
      queryOptions({
        queryKey: ['info'] as const,
        queryFn: () => canaryClient.getInfo(),
        staleTime: ONE_HOUR_MS,
        throwOnError: false,
      }),
  }
  return queries
}
