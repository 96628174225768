import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'

import { reset as resetAnalytics } from '#lib/report/AnalyticsProvider'
import { trackUserSignedOut } from '#lib/report/tracking'

export function useLogoutUser() {
  const { pathname } = useRouter()
  const privateRoutes = ['/account', '/cart', '/checkout']
  const isPrivatePage = privateRoutes.some((route) =>
    pathname.startsWith(route),
  )

  return useMutation({
    mutationFn: async () => {
      await fetch(`/api/auth/logout/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    },
    onMutate: () => {
      trackUserSignedOut()
      resetAnalytics()
    },
    onSettled: () => {
      // We refresh the page to clear any client state that might be hanging around
      if (isPrivatePage) {
        // If the user is on a private page, we send them back to the homepage
        window.location.replace('/')
      } else {
        // otherwise just keep them on the same page
        window.location.reload()
      }
    },
  })
}
