import { useCanaryClient } from '@qogita/canary-client'
import { cn } from '@qogita/ui'
import { useQueryClient } from '@tanstack/react-query'
import Link, { LinkProps } from 'next/link'
import { ComponentPropsWithoutRef } from 'react'
import { getCartQueries, useActiveCart } from 'src/api/cart-queries'
import { UrlObject } from 'url'

import { NextAnchor } from '#components/NextAnchor'

/**
 * Custom hook that provides functionality for generating an active cart link.
 * The link is determined based on the allocation status of the active cart.
 * It also provides a function to prefetch cart data when the link is hovered or focused.
 *
 * @returns An object containing the active cart link, a function to prefetch cart data, and a function to get the active cart link props.
 */
export function useActiveCartLink() {
  const { data: activeCart } = useActiveCart()
  const queryClient = useQueryClient()
  const canaryClient = useCanaryClient()
  const cartQueries = getCartQueries(canaryClient)

  if (!activeCart) return null
  const { qid, allocationStatus } = activeCart
  const unallocatedCartHref = { pathname: '/cart/[qid]', query: { qid } }
  const allocatedCartHref = {
    pathname: '/cart/[qid]/allocated',
    query: { qid },
  }

  const baseHref =
    allocationStatus === 'NOT_ALLOCATED'
      ? unallocatedCartHref
      : allocatedCartHref

  /**
   * Prefetches all relevant cart data to be used to speed up client-side navigation to cart
   */
  function prefetchCartData() {
    if (!activeCart) return
    queryClient.prefetchQuery(cartQueries.detail(activeCart.qid))
    queryClient.prefetchQuery(cartQueries.list())
    if (allocationStatus === 'NOT_ALLOCATED') {
      queryClient.prefetchQuery(
        cartQueries.linesList(activeCart.qid, { page: 1, size: 100 }),
      )
    } else {
      queryClient.prefetchQuery(cartQueries.allocationsList(activeCart.qid))
      queryClient.prefetchQuery(cartQueries.droppedLinesList(activeCart.qid))
    }
  }

  /**
   * Get all props for a link that will navigate to the correct active cart page
   * and prefetch the relevant cart data when hovered or focused
   *
   * @param props - Props to pass to the link component - event handlers will be merged
   * @returns Props to pass to the link component
   */
  function getActiveCartLinkProps({
    onMouseEnter,
    onFocus,
    href: customHref,
    ...props
  }: Omit<
    ComponentPropsWithoutRef<typeof NextAnchor>,
    'href' | 'color' | 'size' | 'appearance'
  > & { href?: (baseHref: UrlObject) => LinkProps['href'] }): Omit<
    ComponentPropsWithoutRef<typeof NextAnchor>,
    'color' | 'size' | 'appearance'
  > {
    const href = customHref ? customHref(baseHref) : baseHref

    return {
      shallow: true,
      href,
      onMouseEnter: (event) => {
        onMouseEnter?.(event)
        prefetchCartData()
      },
      onFocus: (event) => {
        onFocus?.(event)
        prefetchCartData()
      },
      ...props,
    }
  }

  return {
    href: baseHref,
    prefetchCartData,
    getActiveCartLinkProps,
  }
}

/**
 * Links to the cart page. Bare, unstyled Link component.
 * If the cart has not been allocated takes you to the pre-allocation page
 * Otherwise takes you directly to the allocated cart page
 *
 * Be sure to use this component instead of a direct link to the cart page to avoid
 * breaking the cart allocation flow
 *
 * If the active cart isn't available yet, it'll return an invisible span to avoid layout shift
 *
 */
export function ActiveCartLink(
  props: Omit<ComponentPropsWithoutRef<typeof Link>, 'href'> & {
    href?: (baseHref: UrlObject) => LinkProps['href']
  },
) {
  const activeCartLink = useActiveCartLink()
  // If we don't have an active cart fetched yet, we can't render a meaningful link
  // instead render a span that's invisible, so we don't get layout shift from the link popping in
  if (!activeCartLink) {
    return (
      <span aria-hidden className={cn('invisible', props.className)}>
        {props.children}
      </span>
    )
  }
  const { getActiveCartLinkProps } = activeCartLink

  return <Link {...getActiveCartLinkProps(props)} />
}

/**
 * Links to the cart page. Styled design system NextAnchor component.
 * If the cart has not been allocated takes you to the pre-allocation page
 * Otherwise takes you directly to the allocated cart page
 *
 * Be sure to use this component instead of a direct link to the cart page to avoid
 * breaking the cart allocation flow
 *
 * If the active cart isn't available yet, it'll return an invisible span to avoid layout shift
 *
 */
export function ActiveCartAnchor({
  color,
  appearance,
  size,
  ...props
}: Omit<ComponentPropsWithoutRef<typeof NextAnchor>, 'href'> & {
  href?: (baseHref: UrlObject) => LinkProps['href']
}) {
  const activeCartLink = useActiveCartLink()
  // If we don't have an active cart yet, we can't render a meaningful link
  // instead render a span that's invisible, so we don't get layout shift from the link popping in
  if (!activeCartLink) {
    return (
      <span aria-hidden className={cn('invisible', props.className)}>
        {props.children}
      </span>
    )
  }

  const { getActiveCartLinkProps } = activeCartLink

  return (
    <NextAnchor
      color={color}
      size={size}
      appearance={appearance}
      {...getActiveCartLinkProps(props)}
    />
  )
}
