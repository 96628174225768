import {
  AriaButtonOptions,
  AriaButtonProps,
  useButton,
} from '@react-aria/button'
import { useLocale } from '@react-aria/i18n'
import { useNumberField } from '@react-aria/numberfield'
import {
  NumberFieldStateOptions,
  useNumberFieldState,
} from '@react-stately/numberfield'
import {
  ButtonHTMLAttributes,
  createContext,
  forwardRef,
  HTMLAttributes,
  InputHTMLAttributes,
  RefObject,
  useContext,
  useRef,
} from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Input } from '../Input'
import { cn } from '../utils'

type NumberFieldContext = {
  inputProps: HTMLAttributes<HTMLInputElement>
  inputRef: RefObject<HTMLInputElement>
  incrementButtonProps: AriaButtonProps<'button'>
  decrementButtonProps: AriaButtonProps<'button'>
}
const NumberFieldContext = createContext<NumberFieldContext | undefined>(
  undefined,
)
function useNumberFieldContext() {
  const context = useContext(NumberFieldContext)
  if (context === undefined) {
    throw new Error(
      'NumberField compound components must be used within a NumberField',
    )
  }
  return context
}

export interface NumberFieldProps
  extends Omit<HTMLAttributes<HTMLDivElement>, keyof NumberFieldStateOptions>,
    Omit<NumberFieldStateOptions, 'locale' | 'label'> {}

const NumberField = forwardRef<HTMLDivElement, NumberFieldProps>(
  function NumberField({ className, children, ...props }, ref) {
    const { locale } = useLocale()
    const state = useNumberFieldState({ ...props, locale })
    const inputRef = useRef<HTMLInputElement>(null)
    const {
      groupProps,
      // We don't want the ariaLabelledBy from react-aria here as it will conflict with our own label
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      inputProps: { 'aria-labelledby': _ariaLabelledBy, ...inputProps },
      incrementButtonProps,
      decrementButtonProps,
    } = useNumberField(
      {
        ...props,
        /* We have to pass a label prop so useNumberField doesn't throw an error about missing labels
         * but we have our own mechanism for associating labels using FormItem context, so we don't use this label
         */
        label: 'Label',
      },
      state,
      inputRef,
    )

    return (
      <NumberFieldContext.Provider
        value={{
          inputProps,
          incrementButtonProps,
          decrementButtonProps,
          inputRef,
        }}
      >
        <div
          {...groupProps}
          ref={ref}
          className={cn('relative flex', className)}
        >
          {children}
        </div>
      </NumberFieldContext.Provider>
    )
  },
)

type SpinButtonProps = AriaButtonOptions<'button'> &
  ButtonHTMLAttributes<HTMLButtonElement> & { kind: 'increment' | 'decrement' }
const NumberFieldSpinButton = forwardRef<HTMLButtonElement, SpinButtonProps>(
  function SpinButton({ className, children, kind, ...externalProps }, ref) {
    const localRef = useRef<HTMLButtonElement>(null)
    const { incrementButtonProps, decrementButtonProps } =
      useNumberFieldContext()
    const contextProps =
      kind === 'increment' ? incrementButtonProps : decrementButtonProps

    const { buttonProps } = useButton(
      { ...contextProps, ...externalProps },
      localRef,
    )

    return (
      <button
        {...buttonProps}
        ref={mergeRefs([localRef, ref])}
        className={className}
      >
        {children}
      </button>
    )
  },
)

const NumberFieldInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(function NumberFieldInput({ className, ...props }, ref) {
  const { inputProps, inputRef } = useNumberFieldContext()

  return (
    <Input
      {...inputProps}
      {...props}
      ref={mergeRefs([inputRef, ref])}
      className={cn('flex-1', className)}
    />
  )
})

export { NumberField, NumberFieldInput, NumberFieldSpinButton }
