import { cn } from '@qogita/ui'

export function MonduLogo({ className }: { className?: string }) {
  return (
    <svg
      width="61"
      height="16"
      viewBox="0 0 61 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('text-[#8B4AFF]', className)}
    >
      <mask
        id="mask0_11834_96991"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="61"
        height="16"
      >
        <path d="M60.83 0.5H0.830017V15.5H60.83V0.5Z" fill="white" />
      </mask>
      <g mask="url(#mask0_11834_96991)">
        <path
          d="M31.7899 15.133H29.3131V4.97729H31.7261V6.33276C32.3678 5.27787 33.5457 4.6474 34.8011 4.6852C37.3418 4.6852 38.5594 6.47927 38.5594 8.71004V15.1339H36.0826V9.1278C36.0826 7.87728 35.5057 6.87533 33.9474 6.87533C32.5372 6.87533 31.7909 7.93872 31.7909 9.27339V15.133H31.7899Z"
          fill="currentColor"
        />
        <path
          d="M55.5344 15.4253C53.0576 15.4253 51.6047 13.6312 51.6047 11.3796V4.97656H54.0816V10.941C54.0816 12.1916 54.6797 13.2559 56.1742 13.2559C57.6686 13.2559 58.3529 12.3183 58.3529 10.9826V4.97656H60.8297V13.2758C60.8297 14.1094 60.7872 14.7569 60.8297 15.1322H58.4807C58.4594 14.7569 58.4594 14.3808 58.4807 14.0064C57.9687 14.9243 56.7511 15.4243 55.5334 15.4243"
          fill="currentColor"
        />
        <path
          d="M12.7797 4.68431C11.3975 4.6087 10.0909 5.30345 9.4056 6.47838C8.83163 5.32046 7.6024 4.61342 6.28799 4.68431C5.05005 4.65217 3.8847 5.25335 3.21298 6.26948V4.97734H0.830017L0.8426 15.133H3.30009V9.10617C3.30009 7.81305 4.13345 6.85364 5.45657 6.85364C6.8668 6.85364 7.46399 7.77146 7.46399 8.93884V15.133H9.89635V9.10617C9.81693 7.94255 10.7171 6.93682 11.9086 6.85836C11.9569 6.85553 12.0044 6.85364 12.0528 6.85364C13.4408 6.85364 14.0602 7.74972 14.0602 8.93884V15.133H16.4519V8.54089C16.4519 5.85074 14.6584 4.68337 12.7787 4.68337"
          fill="currentColor"
        />
        <path
          d="M50.1257 0.5L47.6053 1.3044V6.16577C47.2849 5.51922 46.3674 4.70632 44.5516 4.70632C41.6237 4.70632 39.5979 7.10438 39.5979 10.0223C39.5979 13.0877 41.6053 15.3837 44.5941 15.3837C45.8263 15.4376 46.9927 14.8403 47.6469 13.8193V15.1333H50.1238C50.1025 14.9669 50.1238 14.1956 50.1238 13.2768V0.5H50.1257ZM44.8729 13.256C43.1859 13.256 42.0757 11.9629 42.0757 10.0232C42.0757 8.08365 43.2721 6.85106 44.8942 6.85106C46.5164 6.85106 47.6276 8.06097 47.6276 10.0006C47.6276 11.9402 46.4961 13.2541 44.8729 13.2541"
          fill="currentColor"
        />
        <path
          d="M22.8862 4.71973C19.7899 4.71973 17.5056 6.99303 17.5056 10.1C17.5056 13.207 19.7909 15.5012 22.8862 15.5012C25.9815 15.5012 28.289 13.2278 28.289 10.1C28.289 6.97223 26.0038 4.71973 22.8862 4.71973ZM25.5605 11.1142C25.5508 11.6115 25.2159 12.0462 24.7291 12.1937L20.3677 13.588C20.3135 13.605 20.2554 13.5757 20.238 13.5227C20.2351 13.5123 20.2332 13.5019 20.2332 13.4916V9.10374C20.2419 8.60654 20.5778 8.17079 21.0646 8.02428L25.4269 6.63194C25.4811 6.61493 25.5392 6.64423 25.5566 6.69716C25.5595 6.70756 25.5614 6.71796 25.5614 6.72836V11.1152L25.5605 11.1142Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
