import { forwardRef, TextareaHTMLAttributes } from 'react'
import { SetRequired } from 'type-fest'

import { cn } from '../utils'

export type TextAreaProps = SetRequired<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  'name'
>

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ rows = 4, className, ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        {...props}
        className={cn(
          'focus:border-primary-500 focus:ring-primary-500 aria-invalid:border-error-700 block w-full rounded-md border-gray-300 p-2 shadow-sm sm:text-sm',
          className,
        )}
        rows={rows}
      />
    )
  },
)
TextArea.displayName = 'TextArea'
