import { createIcon } from '#components/Icon'

export const WhatsAppIcon = createIcon(
  <>
    <g filter="url(#filter0_d_5550_28085)">
      <path
        d="M4.5 18L5.76588 13.3781C4.98383 12.0223 4.57305 10.4842 4.57494 8.91899C4.577 4.001 8.57921 0 13.4973 0C15.8839 0.00121264 18.1241 0.929977 19.8088 2.61592C21.4935 4.30185 22.4205 6.54282 22.4196 8.92615C22.4174 13.8438 18.4146 17.8455 13.4972 17.8455H13.4933C12.0002 17.8449 10.533 17.4703 9.2298 16.7597L4.5 18Z"
        fill="white"
      />
    </g>
    <path
      d="M13.5 1.50673C9.40941 1.50673 6.08276 4.83217 6.08131 8.91963C6.07929 10.3154 6.47234 11.6832 7.21501 12.865L7.39145 13.1454L6.64215 15.8809L9.44894 15.145L9.71997 15.3055C10.8583 15.9811 12.1634 16.3383 13.4944 16.3391H13.4972C17.5847 16.3391 20.9113 13.0133 20.9129 8.92557C20.916 7.95128 20.7258 6.98606 20.3533 6.08578C19.9808 5.1855 19.4334 4.36805 18.7429 3.68076C18.0562 2.98971 17.2392 2.44174 16.3392 2.06856C15.4393 1.69539 14.4743 1.50443 13.5 1.50673Z"
      fill="url(#paint0_linear_5550_28085)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2673 5.19022C11.1002 4.81903 10.9243 4.81151 10.7656 4.80508L10.3383 4.79987C10.1896 4.79987 9.94805 4.85565 9.74384 5.07877C9.53963 5.3019 8.9635 5.84116 8.9635 6.938C8.9635 8.03484 9.76239 9.09469 9.87371 9.2436C9.98503 9.39251 11.416 11.7151 13.682 12.6087C15.5651 13.3513 15.9483 13.2036 16.3572 13.1665C16.7661 13.1294 17.6762 12.6272 17.8619 12.1067C18.0475 11.5861 18.0477 11.1401 17.992 11.0468C17.9363 10.9535 17.7877 10.8981 17.5645 10.7866C17.3414 10.675 16.2455 10.1357 16.0412 10.0613C15.8369 9.98683 15.6883 9.94984 15.5395 10.173C15.3908 10.3961 14.964 10.898 14.8339 11.0468C14.7038 11.1956 14.5739 11.2143 14.3508 11.1028C14.1277 10.9914 13.4098 10.7559 12.558 9.99641C11.8953 9.40549 11.448 8.67572 11.3177 8.45271C11.1875 8.22971 11.3039 8.10893 11.4157 7.99785C11.5158 7.89793 11.6386 7.7375 11.7503 7.60738C11.862 7.47726 11.8987 7.38425 11.9729 7.2357C12.0471 7.08715 12.0101 6.95667 11.9544 6.84523C11.8986 6.73379 11.4654 5.63113 11.2673 5.19022Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_5550_28085"
        x="0.5"
        y="0"
        width="25.9196"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5550_28085"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5550_28085"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_5550_28085"
        x1="13.3461"
        y1="2.39682"
        x2="13.4213"
        y2="15.072"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#57D163" />
        <stop offset="1" stopColor="#23B33A" />
      </linearGradient>
    </defs>
  </>,
  'WhatsAppIcon',
  '4 -4 26 26',
)
