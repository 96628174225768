/**
 * Takes an object and returns a new object with all undefined values replaced with null.
 * Useful for ensuring that all values in an object are serializable.
 */
export function replaceUndefinedValuesWithNull<
  T extends Record<string, unknown>,
>(object: T) {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [key, value ?? null]),
  )
}
