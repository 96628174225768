import { logError } from '@qogita/logging'
import Script from 'next/script'

import { useConsent } from '#contexts/Consent'

// We can move this to environment variables as some point but for now
// We only ever load this script regardless of environment so it's more like
// a constant than an environment variable.
const TIKTOK_ID = 'CR3IEFJC77UC8E5LH8NG'

export function TikTokScript() {
  const { consent } = useConsent()

  const isConsentAccepted =
    consent.status !== 'loading' && consent.value.marketing

  const shouldLoadScript = isConsentAccepted

  return shouldLoadScript ? (
    <Script id="tiktok">
      {`
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
          var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
          ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


          ttq.load('${TIKTOK_ID}');
          ttq.page();
        }(window, document, 'ttq');
      `}
    </Script>
  ) : null
}

// We need to hash emails and phone numbers before sending them to ad attribution services
// This is because we don't want to send PII to third parties
// This function is taken almost verbatim from the MDN Web Crypto API documentation
// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
async function hashString(input: string) {
  if (!window.crypto) throw new Error('Crypto API not available')

  const inputUint8 = new TextEncoder().encode(input)
  const hashBuffer = await crypto.subtle.digest('SHA-256', inputUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHexString = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')

  return hashHexString
}
export async function tikTokIdentifyUser({
  email,
  phoneNumber,
}: {
  email: string
  phoneNumber: string | null
}) {
  if (!window.ttq) return
  if (!window.crypto) return

  try {
    const hashedEmail = await hashString(email)
    const hashedPhoneNumber = phoneNumber ? await hashString(phoneNumber) : null

    const hashedUserDetails: {
      email: string
      phone_number?: string
    } = {
      email: hashedEmail,
    }

    if (hashedPhoneNumber) {
      hashedUserDetails['phone_number'] = hashedPhoneNumber
    }

    window.ttq.identify(hashedUserDetails)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}

// This overload is pretty huge, it's here to give us a type safe way to call the TikTok tracking
// function. It means that we can't call the function with an event that doesn't exist or with
// parameters that don't match the event.
// We can always move it to its own interface if it becomes irritating to work with
type ContentType = 'product' | 'product_group'
export function tikTokEvent(
  event: 'ViewContent',
  parameters: {
    contents: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value: number
    currency?: string
  },
): void
export function tikTokEvent(
  event: 'Search',
  parameters: {
    query: string
  },
): void
export function tikTokEvent(
  event: 'AddToCart',
  parameters: {
    contents: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value: number
    currency: string
  },
): void
export function tikTokEvent(
  event: 'InitiateCheckout',
  parameters: {
    value: number
    currency: string
  },
): void
export function tikTokEvent(
  event: 'PlaceAnOrder',
  parameters: {
    contents: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value: number
    currency: string
  },
): void
export function tikTokEvent(
  event: 'CompleteRegistration',
  parameters?: undefined,
): void
export function tikTokEvent(
  event: string,
  parameters?: {
    contents?: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value?: number
    currency?: string
    order_id?: string
    query?: string
  },
) {
  if (!window.ttq) return

  try {
    window.ttq.track(event, parameters)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}

export function tikTokCustomEvent(
  event: string,
  parameters?: {
    contents?: Array<{
      content_id?: string
      content_name?: string
      content_type?: ContentType
    }>
    value?: number
    currency?: string
    order_id?: string
    status?: string
  },
) {
  if (!window.ttq) return

  try {
    window.ttq.track(event, parameters)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}
