import dolceGabbanaLogo from './assets/brands/dolce-gabbana.webp'
import garnierLogo from './assets/brands/garnier.webp'
import lattafaLogo from './assets/brands/lattafa.webp'
import lorealLogo from './assets/brands/loreal.webp'
import maybellineLogo from './assets/brands/maybelline.webp'
import niveaLogo from './assets/brands/nivea.webp'
import oralBLogo from './assets/brands/oral-b.webp'
import schwarzkopfLogo from './assets/brands/schwarzkopf.webp'
import theBodyShopLogo from './assets/brands/the-body-shop.webp'
import versaceLogo from './assets/brands/versace.webp'

export type NavigationCategory = {
  name: string
  slug?: string
  subCategories: {
    name: string
    slug?: string
    subCategories: {
      name: string
      slug: string
    }[]
  }[]
}

export type NavigationSubCategory = {
  name: string
  slug?: string
  subCategories?: NavigationSubCategory[]
}

export const navigationCategories: Record<
  | 'makeup'
  | 'hair'
  | 'skincare'
  | 'bathShower'
  | 'nailCare'
  | 'shavingGrooming'
  | 'fragrance',
  NavigationCategory
> = {
  fragrance: {
    name: 'Fragrance',
    subCategories: [
      {
        name: 'Perfume & Cologne',
        slug: 'personal-care/cosmetics/perfume-cologne',
        subCategories: [],
      },
      {
        name: 'Aftershave',
        slug: 'personal-care/shaving-grooming/aftershave',
        subCategories: [],
      },
      {
        name: 'Deodorant & Anti-perspirant',
        slug: 'personal-care/deodorants-anti-perspirants',
        subCategories: [],
      },
    ],
  },
  makeup: {
    name: 'Makeup',
    slug: 'personal-care/cosmetics/make-up',
    subCategories: [
      {
        name: 'Eye Makeup',
        slug: 'personal-care/cosmetics/make-up/eye-make-up',
        subCategories: [
          {
            name: 'Eye Shadow',
            slug: 'personal-care/cosmetics/make-up/eye-make-up/eye-shadow',
          },
          {
            name: 'Eye Liner',
            slug: 'personal-care/cosmetics/make-up/eye-make-up/eyeliner',
          },
          {
            name: 'Mascara',
            slug: 'personal-care/cosmetics/make-up/eye-make-up/mascara',
          },
          {
            name: 'Eyebrow Enhancers',
            slug: 'personal-care/cosmetics/make-up/eye-make-up/eyebrow-enhancers',
          },
        ],
      },
      {
        name: 'Face Makeup',
        slug: 'personal-care/cosmetics/make-up/face-makeup',
        subCategories: [
          {
            name: 'Concealers',
            slug: 'personal-care/cosmetics/make-up/face-makeup/concealers',
          },
          {
            name: 'Contour',
            slug: 'personal-care/cosmetics/make-up/face-makeup/contouring',
          },
          {
            name: 'Face Powders',
            slug: 'personal-care/cosmetics/make-up/face-makeup/face-powders',
          },
          {
            name: 'Face Primer',
            slug: 'personal-care/cosmetics/make-up/face-makeup/face-primer',
          },
          {
            name: 'Foundations & Primers',
            slug: 'personal-care/cosmetics/make-up/face-makeup/foundations-powders',
          },
          {
            name: 'Highlighters & Luminisers',
            slug: 'personal-care/cosmetics/make-up/face-makeup/highlighters-luminisers',
          },
        ],
      },
      {
        name: 'Lip Makeup',
        slug: 'personal-care/cosmetics/make-up/lip-makeup',
        subCategories: [
          {
            name: 'Lip Gloss',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lip-gloss',
          },
          {
            name: 'Lip Liner',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lip-liner',
          },
          {
            name: 'Lip Primer',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lip-primer',
          },
          {
            name: 'Lipstick',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lipstick',
          },
          {
            name: 'Lip & Cheek Stain',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lip-cheek-stains',
          },
        ],
      },
    ],
  },
  hair: {
    name: 'Hair',
    slug: 'personal-care/haircare',
    subCategories: [
      {
        name: 'Hair Care',
        slug: 'personal-care/haircare',
        subCategories: [
          {
            name: 'Shampoo & Conditioner',
            slug: 'personal-care/haircare/shampoo-conditioner',
          },
          {
            name: 'Hair Masks',
            slug: 'personal-care/haircare/hair-masks',
          },
          {
            name: 'Hair Colouring',
            slug: 'personal-care/haircare/hair-colouring',
          },
          {
            name: 'Hair Loss Treatments',
            slug: 'personal-care/haircare/hair-loss-treatments',
          },
        ],
      },
      {
        name: 'Hair Styling',
        subCategories: [
          {
            name: 'Hair Combs & Brushes',
            slug: 'personal-care/haircare/hair-styling-tools/combs-brushes/',
          },
          {
            name: 'Hair Curlers',
            slug: 'personal-care/haircare/hair-styling-tools/hair-curlers/',
          },
          {
            name: 'Hair Dryers',
            slug: 'personal-care/haircare/hair-styling-tools/hair-dryers',
          },
          {
            name: 'Hair Straighteners',
            slug: 'personal-care/haircare/hair-styling-tools/hair-straighteners',
          },
          {
            name: 'Hair Styling Accessories',
            slug: 'personal-care/haircare/hair-styling-tool-accessories',
          },
        ],
      },
    ],
  },
  skincare: {
    name: 'Skincare',
    slug: 'personal-care/cosmetics/skincare',
    subCategories: [
      {
        name: 'Face',
        subCategories: [
          {
            name: 'Facial Cleansers',
            slug: 'personal-care/cosmetics/skincare/facial-cleansers',
          },
          {
            name: 'Toners',
            slug: 'personal-care/cosmetics/skincare/toners-astringents/toners',
          },
          {
            name: 'Lip Balms',
            slug: 'personal-care/cosmetics/skincare/lip-balms-treatments',
          },
          {
            name: 'Face Masks',
            slug: 'personal-care/cosmetics/skincare/skin-care-masks-peels',
          },
          {
            name: 'Makeup Removers',
            slug: 'personal-care/cosmetics/skincare/make-up-removers',
          },
          {
            name: 'Astringents',
            slug: 'personal-care/cosmetics/skincare/toners-astringents/astringents',
          },
        ],
      },
      {
        name: 'Body',
        subCategories: [
          {
            name: 'Body Oil',
            slug: 'personal-care/cosmetics/skincare/body-oil',
          },
          {
            name: 'Body Powder',
            slug: 'personal-care/cosmetics/skincare/body-powder',
          },
          {
            name: 'Lotions & Moisturisers',
            slug: 'personal-care/cosmetics/skincare/lotions-moisturisers',
          },
          {
            name: 'Petroleum Jelly',
            slug: 'personal-care/cosmetics/skincare/petroleum-jelly',
          },
        ],
      },
      {
        name: 'Sun Care & Tanning',
        subCategories: [
          {
            name: 'Sunscreen',
            slug: 'personal-care/cosmetics/skincare/sunscreen',
          },
          {
            name: 'Self Tanning',
            slug: 'personal-care/cosmetics/skincare/tanning-products',
          },
        ],
      },
    ],
  },
  bathShower: {
    name: 'Bath & Shower',
    slug: 'personal-care/cosmetics/bath-body/',
    subCategories: [
      {
        name: 'Cleansers',
        subCategories: [
          {
            name: 'Hand Sanitiser',
            slug: 'personal-care/cosmetics/bath-body/hand-sanitisers',
          },
          {
            name: 'Liquid Hand Soap',
            slug: 'personal-care/cosmetics/bath-body/liquid-hand-soap',
          },
          {
            name: 'Body Wash',
            slug: 'personal-care/cosmetics/bath-body/body-wash',
          },
          {
            name: 'Bar Soap',
            slug: 'personal-care/cosmetics/bath-body/bar-soap',
          },
          {
            name: 'Bath Additives',
            slug: 'personal-care/cosmetics/bath-body/bath-additives',
          },
          {
            name: 'Hygienic Wipes',
            slug: 'personal-care/cosmetics/bath-body/adult-hygienic-wipes',
          },
        ],
      },
      {
        name: 'Accessories',
        subCategories: [
          {
            name: 'Bath Sponges & Loofahs',
            slug: 'personal-care/cosmetics/bath-body/bath-sponges-loofahs',
          },
          {
            name: 'Bath Brushes',
            slug: 'personal-care/cosmetics/bath-body/bath-brushes',
          },
        ],
      },
    ],
  },
  nailCare: {
    name: 'Nail Care',
    slug: 'personal-care/cosmetics/nail-care',
    subCategories: [
      {
        name: 'Nail Design',
        subCategories: [
          {
            name: 'Nail Polish',
            slug: 'personal-care/cosmetics/nail-care/nail-polishes',
          },
          {
            name: 'Nail Polish Remover',
            slug: 'personal-care/cosmetics/nail-care/nail-polish-removers',
          },
          {
            name: 'False Nails',
            slug: 'personal-care/cosmetics/nail-care/false-nails',
          },
          {
            name: 'Nail Kits',
            slug: 'personal-care/cosmetics/nail-care/nail-art-kits-accessories',
          },
          {
            name: 'Nail Creams & Oils',
            slug: 'personal-care/cosmetics/nail-care/cuticle-creams-oils',
          },
        ],
      },
      {
        name: 'Nail Tools',
        slug: 'personal-care/cosmetics/cosmetic-tools/nail-tools',
        subCategories: [
          {
            name: 'Nail Clippers',
            slug: 'personal-care/cosmetics/cosmetic-tools/nail-tools/nail-clippers',
          },
          {
            name: 'Nail Buffers',
            slug: 'personal-care/cosmetics/cosmetic-tools/nail-tools/nail-buffers',
          },
          {
            name: 'Manicure Tool Sets',
            slug: 'personal-care/cosmetics/cosmetic-tools/nail-tools/manicure-tool-sets',
          },
        ],
      },
    ],
  },
  shavingGrooming: {
    name: 'Shaving & Grooming',
    slug: 'personal-care/shaving-grooming',
    subCategories: [
      {
        name: 'Shaving',
        subCategories: [
          {
            name: 'Electric Razors',
            slug: 'personal-care/shaving-grooming/electric-razors',
          },
          {
            name: 'Hair Clippers & Trimmers',
            slug: 'personal-care/shaving-grooming/hair-clippers-trimmers',
          },
          {
            name: 'Razors',
            slug: 'personal-care/shaving-grooming/razors-razor-blades',
          },
          {
            name: 'Shaving Kits',
            slug: 'personal-care/shaving-grooming/shaving-kits',
          },
        ],
      },
      {
        name: 'Hair Removal',
        slug: 'personal-care/shaving-grooming/hair-removal',
        subCategories: [
          {
            name: 'Waxing Kits',
            slug: 'personal-care/shaving-grooming/hair-removal/waxing-kits-supplies',
          },
          {
            name: 'Laser Hair Removal',
            slug: 'personal-care/shaving-grooming/hair-removal/laser-ipl-hair-removal-devices/',
          },
          {
            name: 'Hair Removal Cream',
            slug: 'personal-care/shaving-grooming/hair-removal/depilatories',
          },
        ],
      },
      {
        name: 'Accessories',
        subCategories: [
          {
            name: 'Shaving Cream',
            slug: 'personal-care/shaving-grooming/shaving-cream',
          },
          {
            name: 'Shaving Brushes',
            slug: 'personal-care/shaving-grooming/shaving-brushes',
          },
          {
            name: 'Shaving Bowls',
            slug: 'personal-care/shaving-grooming/shaving-bowls-mugs',
          },
        ],
      },
    ],
  },
}

export const navigationBrands = [
  {
    name: 'Maybelline New York',
    slug: 'maybelline-new-york',
    logo: maybellineLogo,
  },
  { name: 'Garnier', slug: 'garnier', logo: garnierLogo },
  { name: 'Dolce & Gabbana', slug: 'dolce-gabbana', logo: dolceGabbanaLogo },
  { name: 'Nivea', slug: 'nivea', logo: niveaLogo },
  { name: 'Versace', slug: 'versace', logo: versaceLogo },
  { name: 'Lattafa', slug: 'lattafa', logo: lattafaLogo },
  { name: "L'Oréal", slug: 'loreal', logo: lorealLogo },
  { name: 'Schwarzkopf', slug: 'schwarzkopf', logo: schwarzkopfLogo },
  { name: 'Oral-B', slug: 'oral-b', logo: oralBLogo },
  { name: 'The Body Shop', slug: 'the-body-shop', logo: theBodyShopLogo },
]
